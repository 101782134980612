import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { Badge, Tag } from "antd";
import ProductDetailsModal from "../../../Modals/ProductDetailsModal";
import UpdateProduct from "../../../forms/NewProductForm";
import DeleteModal from "../../../Modals/DeleteModal";
import { getActiveShop, getTotalPrice } from "../../../../utils/converter";
import { Link } from "react-router-dom";
import { searchValue } from "../../../../utils/setColor";
import {
  deleteCartAction,
  getAllCartsAction,
} from "../../../../store/pos/actions";
import { getDefaultCurrencyCode } from "../../../../utils/CurrencyHelper";
import { handleOpenSellingModeAction } from "../../../../store/layout/actions";

const CartCardGridPOS = (props: any) => {
  const { auth, pos, appSettings, company } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [visible, setVisible] = useState(false);

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const handleOnClickViewIcon = (e: any) => {
    e.preventDefault();
    // setisEdit(false);
    setIsModalOpen(true);
  };

  const handleDeleteModalCancels = () => {
    setVisible(false);
  };
  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  const deleteProduct = async () => {
    const activeShop = getActiveShop(auth?.user?.shop?.assigned);
    const shopId = activeShop
      ? activeShop[appSettings?.selectedShopNumber]?.shop?._id
      : auth?.userShop?.shopId;
    auth?.token &&
      (await deleteCartAction(
        auth?.token,
        props?.data?._id,
        `?shop=${shopId}&status=paid`
      )(dispatch));
    auth?.token &&
      (await getAllCartsAction(
        auth?.token,
        `?shop=${
          activeShop[appSettings?.selectedShopNumber]?.shop?._id ??
          auth?.userShop?.shopId
        }&status[]=pending&status[]=accepted&status[]=rejected&status[]=requested&status[]=inquiry`
      )(dispatch));
    setVisible(false);
  };

  return (
    <>
      {/* {isCustomerExist &&   */}
      <Badge.Ribbon
        color="#E1F7DE"
        text={
          <h1 className="text-[#3AC722] text-[12px] font-semibold">
            {" "}
            {`Total amount: ${getTotalPrice(
              props?.data?.list
            )?.toLocaleString()} ${getDefaultCurrencyCode(company)}`}
          </h1>
        }
      >
        <div className="relative w-80 h-fit p-2  bg-white text-[#030229] rounded-md">
          <Link
            to={`${props?.data?._id}`}
            onClick={async () =>
              props?.data?.isSubscription === ""
                ? await handleOpenSellingModeAction(true)(dispatch)
                : ""
            }
          >
            <div className="flex w-full pb-10 pt-10">
              <div className="flex flex-col flex-wrap pl-4">
                <h1 className="text-[#030229B2] text-sm">
                  {props?.data?.cartId}
                </h1>
                <div className="gap-1">
                  <p className="text-xs">
                    <span className="text-[#030229] text-sm">Customer: </span>
                    <span className="text-[#030229B2] text-sm">
                      {props?.data?.customer?.name}
                    </span>
                  </p>
                  <p className="text-xs">
                    <span className="text-[#030229] text-sm">
                      Created Date:{" "}
                    </span>
                    <span className="text-[#030229B2] text-sm">
                      {" "}
                      {new Date(props?.data?.createdAt).toLocaleDateString(
                        "en-US",
                        {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        }
                      )}
                    </span>
                  </p>
                  <p className="text-xs">
                    <span className="text-[#030229] text-sm">Created By: </span>
                    <span className="text-[#030229B2] text-sm">
                      {props?.data?.createdBy?.names}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </Link>
          <div className="absolute bottom-1 right-2 flex justify-end items-center w-full py-2 space-x-2">
            <div className=" capitalize">
              <Tag
                color={searchValue(props?.data?.isSubscription)}
                style={{
                  minWidth: "4rem",
                  textAlign: "center",
                  border: "0.1px solid #faad14d0",
                }}
                // className="border border-[#faad14d0]"
              >
                <span
                  className={`${
                    props?.data?.isSubscription?.toLowerCase() === "pos"
                      ? "text-[#FAAD14]"
                      : "text-[#B17F69]"
                  } text-sm`}
                >
                  {props?.data.isSubscription}
                </span>
              </Tag>
              <Tag
                color={searchValue(props?.data.status)}
                style={{ minWidth: "4rem", textAlign: "center" }}
              >
                <span className="text-[#030229B2] text-sm">
                  {" "}
                  {props?.data.status}
                </span>
              </Tag>
            </div>
            {!["paid", "rejected"]?.includes(
              props?.data?.status?.toLowerCase()
            ) &&
              ![
                "PAYMENT_DONE",
                "VAULT_UPLOADED",
                "COMPLETED",
                "VAULT_ACTIVATED",
              ]?.includes(props?.data?.subscriptionStatus) && (
                <button
                  aria-label="delete"
                  className="text-gray-400"
                  onClick={handleClickDelete}
                >
                  <DeleteIcon fontSize="small" className="h-2" />
                </button>
              )}
          </div>
        </div>
      </Badge.Ribbon>

      {/* product modal here */}

      <ProductDetailsModal
        component={
          <UpdateProduct
            dataToUpdate={props?.data}
            action={"update"}
            onCancel={handleOnCancel}
          />
        }
        isModalOpen={isModalOpen}
        handleOnClickViewIcon={handleOnClickViewIcon}
        handleOnCancel={handleOnCancel}
      />

      <DeleteModal
        visible={visible}
        onOk={deleteProduct}
        isLoading={pos.isDeleteFetching}
        onCancel={handleDeleteModalCancels}
        itemName="Product"
      />
    </>
  );
};

export default CartCardGridPOS;
