import React, { useState, useRef } from "react";
import {
  Divider,
  Form,
  Input,
  Select,
  Space,
  InputRef,
  notification,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { MinusCircleOutlined } from "@ant-design/icons";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { relationship } from "../../../../assets/data/relationship";
import { LoadingButton } from "@mui/lab";
import { ProgressInfobar } from "../ProgresssInfoBar";
import { updateShopStockCartDetailsAction } from "../../../../store/wareHouse/actions";
import { updateCustomerAction } from "../../redux/businessPlans/action";
import { useParams } from "react-router-dom";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const WitnessForm = (props: any) => {
  const { auth, wareHouse } = useSelector((state: any) => state);
  const [selectedCountry, setSelectedCountry] = useState("250");
  const [form] = Form.useForm();
  const { cartId } = useParams();
  let indexBrand = 0;
  const [loading, setIsLoading] = useState(false);
  const [loadingStep, setLoadingStep] = useState<any>(null);

  const [items, setItems] = useState([...relationship]);
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);
  const dispatch = useDispatch();
  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setItems([...items, name || `New item ${indexBrand++}`]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleCountryChange = (country: any) => {
    setSelectedCountry(country);
  };
  const handlePrevious = async () => {
    setLoadingStep("back");
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      subscriptionStep: {
        name: "ADDRESS_DETAIL",
        index: 2,
      },
    })(dispatch);
  };
  const initialData = {
    ...wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address,
  };
  const addressData =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address;
  const incomeFrequency =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.incomeFrequency;
  const mainIncomeSource =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.mainIncomeSource;
  const monthlyIncome =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.monthlyIncome;
  const ownsSmartphone =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.ownsSmartphone;
  const occupationOrBusinessType =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.occupationOrBusinessType;
  const userImage =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.photoCustomerHoldingID;
  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;

  const onFinish = async (values: any) => {
    setLoadingStep("next");
    const updatedWitnessInfo = values.witnessInfo.map((witness: any) => ({
      ...witness,
      name: `${witness.name} ${witness.lastName}`,
    }));
    setIsLoading(true);
    const payload = {
      ...values,
      witnessInfo: updatedWitnessInfo,
      subscriptionInfo: {
        ...values,
        address: addressData,
        witnessInfo: values.witnessInfo,
        incomeFrequency: incomeFrequency,
        mainIncomeSource: mainIncomeSource,
        monthlyIncome: monthlyIncome,
        ownsSmartphone: ownsSmartphone,
        occupationOrBusinessType: occupationOrBusinessType,
        nationalIDDoc: nationalId,
        photoCustomerHoldingID: userImage,
      },
    };
    const hasMuduguduRelationship = values?.witnessInfo?.some(
      (witness: any) => witness?.relationship?.toLowerCase() === "mudugudu"
    );

    if (!hasMuduguduRelationship) {
      setIsLoading(false);
      notification.info({
        message: "Please make sure one of the Contact Person is Mudugudu.",
      });
      return;
    }
    const res = await updateCustomerAction(
      auth?.token,
      wareHouse?.createdCart?.data?.customer?._id,
      payload
    )(dispatch);
    if (res?.type) {
      setIsLoading(false);
      await updateShopStockCartDetailsAction(auth?.token, cartId, {
        subscriptionStep: {
          name: "ACCEPT_CONSENT",
          index: 4,
        },
      })(dispatch);
    }
  };
  return (
    <div>
      <ProgressInfobar name={"Witness Details"} percentage={50} />
      <div className="mb-5">
        <h1 className="text-[#030229] text-lg mb-5 font-medium">
          Other Contacts
        </h1>
        <Space
          style={{
            background: "#fed607",
            padding: "1px 10px",
            borderRadius: "5px",
            marginBottom: "10px",
          }}
        >
          <p className="font-normal text-[14px]">
            One of Contact Person must be MUDUGUDU
          </p>
        </Space>
      </div>
      <Form
        form={form}
        name="register"
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialData || {}}
      >
        <Form.List
          name="witnessInfo"
          {...formItemLayout}
          initialValue={
            wareHouse?.createdCart?.data?.customer?.subscriptionInfo &&
            wareHouse?.createdCart?.data?.customer?.subscriptionInfo
              ?.witnessInfo?.length !== 0
              ? [
                  ...wareHouse?.createdCart?.data?.customer?.subscriptionInfo
                    ?.witnessInfo,
                ]
              : [{}, {}]
          }
        >
          {(fields, { add, remove }) => {
            const addButtonDisabled = fields.length >= 4;
            return (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div>
                    <div className="grid md:grid-cols-2 2xl:grid-cols-3 gap-x-4 lg:max-2xl:gap-1 mt-2 lg:max-2xl:mt-0 ">
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        label={
                          <span className="text-[#0F0F47] text-sm">
                            First Name
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Names are required!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="FirstName"
                          className="pl-4 border"
                          style={{ height: 50 }}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "lastName"]}
                        // name={[name, "lastName"]}
                        label={
                          <span className="text-[#0F0F47] text-sm">
                            Last Name
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Names are required!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Last Name"
                          className="pl-4 border"
                          style={{ height: 50 }}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "phone"]}
                        label={
                          <span className="text-[#0F0F47] text-sm">Phone</span>
                        }
                        initialValue={props?.dataToUpdate?.phone}
                        rules={[
                          {
                            validator: (_, value) => {
                              const phoneNumberLength = value
                                ? value.replace(/\D/g, "").length
                                : 0;
                              if (selectedCountry?.slice(0, 3) === "250") {
                                if (phoneNumberLength === 12) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "Phone Number should be exactly 9 Digits."
                                  )
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                      >
                        <PhoneInput
                          onChange={handleCountryChange}
                          country={"rw"}
                          buttonStyle={{
                            height: "50px",
                            border: "1px solid #E5E7EB",
                          }}
                          inputStyle={{
                            height: "50px",
                            width: "100%",
                            border: "1px solid #E5E7EB",
                          }}
                          containerClass="phone-container"
                          placeholder="+250 780 000 000"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "relationship"]}
                        label={
                          <span className="text-[#0F0F47] text-sm">
                            RelationShip
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Relationsip is required!",
                          },
                        ]}
                        initialValue={name === 0 ? "mudugudu" : null}
                      >
                        <Select
                          style={{ display: "flex", alignItems: "center" }}
                          className="h-[50px]"
                          disabled={name === 0 ? true : false}
                          showSearch
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider style={{ margin: "8px 0" }} />
                              <Space style={{ padding: "0 8px 4px" }}>
                                <Input
                                  placeholder="Add New"
                                  ref={inputRef}
                                  onChange={onNameChange}
                                />
                                <Button variant="contained" onClick={addItem}>
                                  <PlusOutlined />
                                </Button>
                              </Space>
                            </>
                          )}
                          options={items.map((item) => ({
                            label: item,
                            value: item,
                          }))}
                        />
                      </Form.Item>

                      {key >= 2 && (
                        <div className="flex flex-row items-start mt-8 xgap-2">
                          <Button
                            onClick={() => remove(name)}
                            className=" shadow !m-0 justify-between !items-center text-red-500"
                          >
                            <MinusCircleOutlined className=" my-auto text-red-500" />
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div>
                  <Form.Item>
                    <div className="flex items-center gap-x-3">
                      <Button
                        onClick={() => !addButtonDisabled && add()}
                        className="shadow !m-0 justify-between !items-center text-[#2943D6]"
                        // disabled={
                        //   wareHouse?.createdCart?.data?.isSubscriptionSubmitted
                        // }
                      >
                        <PlusOutlined />
                      </Button>
                      <span className="text-blue-600"> Add More Contact</span>
                    </div>
                  </Form.Item>
                </div>
              </>
            );
          }}
        </Form.List>
        <div className="py-6 pl-4 flex-row  flex items-center justify-between  gap-4">
          <LoadingButton
            variant="outlined"
            style={{
              width: "100px",
              height: 40,
            }}
            onClick={handlePrevious}
            loading={loadingStep === "back" && wareHouse?.isUpdateFetching}
            disabled={wareHouse?.isUpdateFetching}
          >
            Previous
          </LoadingButton>
          <div className=" flex items-center gap-x-1 mr-5 2xl:gap-x-4 ">
            <LoadingButton
              type="submit"
              variant="contained"
              style={{
                padding: "10px",
              }}
              loading={
                loading ||
                (loadingStep === "next" && wareHouse?.isUpdateFetching)
              }
              disabled={loading || wareHouse?.isUpdateFetching}
            >
              {"Save and Continue"}
            </LoadingButton>
          </div>
        </div>
      </Form>
      {/* </Form> */}
    </div>
  );
};
export default WitnessForm;
