import React from "react";
import { Badge, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import {useSelector } from "react-redux";
import { getDefaultCurrencyCode } from "../../../../utils/CurrencyHelper";
import { searchValue } from "../../../../utils/setColor";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import ThreeDotDropdown from "../../POS/plans/threeDotDropdown";

interface DataType {
  [x: string]: any;
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const CashsalesPage = (props: any) => {
  const { pos, company,auth } = useSelector((state: any) => state);

 
  const columns: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
    },
    {
      title: "Agent",
      dataIndex: "agent",
      key: "agent",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Emie",
      dataIndex: "emie",
      key: "emie",
    },
    {
      title: "Data Activated",
      dataIndex: "dataActivated",
      key: "dataActivated",
      render: (text: any, record: any) => (
        <p>{`${record?.dataActivated ? "Yes" : " No"}`}</p>
      ),
    },
    {
      title: `Price (${getDefaultCurrencyCode(company)})`,
      dataIndex: "amount",
      key: "amount",
      render: (_text, record) => <p>{record?.amount?.toLocaleString()} </p>,
    },
    {
      title: "Discount",
      dataIndex: "data",
      key: "data",
      render: (_text, record) => {
        return <p>{record?.data?.discount?.discountPerc} %</p>;
      },
    },
    {
      title: "Payment Method",
      dataIndex: "payment",
      key: "payment",
      render: (_text: any, record: any) => (
        <p>
          {record?.data?.payment?.map((d: any) =>
            d?.mode?.split("_")?.join(" ")
          )}
        </p>
      ),
    },
    {
      title: `Total Amount (${getDefaultCurrencyCode(company)})`,
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <Tag
          className="capitalize min-w-[70px] text-center"
          color={
            record?.data?.payment?.amount +
              (record?.data?.payment?.amount *
                record?.data?.payment?.extendedWarranty) /
                100 -
              (record?.data?.payment?.amount *
                record?.data?.cart?.discount?.discountPerc) /
                100 ===
            record?.data?.cart?.payment?.reduce(
              (sum: number, a: any) => (sum = sum + a?.amount),
              0
            )
              ? "success"
              : record?.data?.payment?.amount +
                  (record?.data?.payment?.amount *
                    record?.data?.payment?.extendedWarranty) /
                    100 -
                  (record?.data?.payment?.amount *
                    record?.data?.cart?.discount?.discountPerc) /
                    100 >
                record?.data?.cart?.payment?.reduce(
                  (sum: number, a: any) => (sum = sum + a?.amount),
                  0
                )
              ? "error"
              : "cyan-inverse"
          }
        >
          {text}
        </Tag>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (text, record) => (
        <span className="flex items-center">
          <Tag
            color={searchValue(record?.data?.status)}
            className="capitalize min-w-[50px] text-center"
          >
            {record?.data?.status}{" "}
          </Tag>
          {record?.data?.receiptCode &&
            ["admin", "dev"]?.includes(auth?.user?.role) && (
              <Badge count={"RRA"} offset={[0, 0]} color="magenta"></Badge>
            )}
        </span>
      ),
    },
    
  ];

  const formData = pos?.cashsales?.data?.map((el: any) => {
    return {
      id: el?._id,
      date_time: el?.updatedAt?.split("T")[0],
      agent: el?.createdBy?.names,
      shop: el?.shop?.name,
      model:
        el?.list?.map((item: any) => item?.priceProduct?.product?.model) ||
        "N/A",
      emie:
        el?.list?.map((item: any) => item?.warehouseProduct?.serialNumber) ||
        "N/A",
      amount: el?.payment[0]?.amount,
      status: el?.status,
      barCode: el?.warehouseProduct?.serialNumber,
      dataActivated: el?.list?.at(0)?.warehouseProduct?.dataActivated || "N/A",
      customer: el?.cart?.customer?.name,
      data: el,
    };
  });


  return (
    <div className="overflow-x-auto">
      <ScrollableFrame
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props?.limit}
        total={pos?.transaction?.total}
        count={Math.ceil(pos?.transaction?.total / props?.limit)}
        isTable
      >
        <Table
          className="piece_sold"
          columns={columns}
          dataSource={formData}
          scroll={{ y: "65vh" }}
          loading={pos?.isFetching}
        />
      </ScrollableFrame>
    </div>
  );
};

export default CashsalesPage;
