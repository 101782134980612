import { Skeleton } from "antd";
import React from "react";
import { CiEdit } from "react-icons/ci";
import { useSelector } from "react-redux";

const PersonalDetailsData = (props: any) => {
  const { auth, apllicants } = useSelector((state: any) => state);
  const phone = apllicants?.applicantDetails?.data?.customer?.phone;
  const maskedPhone = phone
    ? `${phone.slice(0, 5)}*********${phone.slice(-2)}`
    : "";
  const NId = apllicants?.applicantDetails?.data?.customer?.nationalId;
  const maskedNID = NId ? `${NId.slice(0, 2)}*********${NId.slice(-2)}` : "";
  return (
    <div>
      {apllicants?.isFetching ? (
        <Skeleton />
      ) : (
        <div className="w-full md:flex gap-5">
          <div className="border border-[#0302291A] w-full p-4 mt-5 rounded-md">
            <div className="flex justify-between">
              <h1 className="text-[#030229] text-base font-medium py-3 pb-6 text-center">
                Personal Details
              </h1>
              <div
                className="bg-[#E5E5E5] rounded-md h-[26px] w-[56px] flex justify-center items-center gap-2 cursor-pointer"
                //   onClick={showReceiverModal}
              >
                <CiEdit size={15} />
                <p className="text-[12px]">Edit</p>
              </div>
            </div>
            <div className="flex w-full gap-12 mt-6">
              <p className="text-[#03022980]  text-sm">Full Name</p>
              <p className="text-[#030229] text-sm">
                {apllicants?.applicantDetails?.data?.customer?.name}
              </p>
            </div>
            <div className="flex w-full gap-5 mt-6">
              <p className="text-[#03022980]  text-sm">Personal Email</p>
              <p className="text-[#030229] text-sm">
                {apllicants?.applicantDetails?.data?.customer?.email}
              </p>
            </div>
            <div className="flex w-full gap-5 mt-3">
              <p className="text-[#03022980]  text-sm">Phone Number</p>
              <p className="text-[#030229] text-sm">{maskedPhone}</p>
            </div>
            <div className="flex w-full gap-12 mt-3">
              <p className="text-[#03022980]  text-sm">National Id</p>
              <p className="text-[#030229] text-sm">{maskedNID}</p>
            </div>
            <div className="flex items-end justify-end w-full">
              <div className="text-[#605BFF] py-1 px-2 rounded-md text-base border border-[#605BFF] w-[13.5rem] mt-4">
                <p>View Other Contacts (2)</p>
              </div>
            </div>
          </div>
          <div className="border border-[#0302291A] p-4 mt-5 rounded-md w-full">
            <div className="flex justify-between">
              <h1 className="text-[#030229] text-base font-medium py-3 pb-6 text-center">
                Address Details
              </h1>
              <div
                className="bg-[#E5E5E5] rounded-md h-[26px] w-[56px] flex justify-center items-center gap-2 cursor-pointer"
                //   onClick={showReceiverModal}
              >
                <CiEdit size={15} />
                <p className="text-[12px]">Edit</p>
              </div>
            </div>
            <div className="flex w-full gap-10 mt-3">
              <p className="text-[#03022980]  text-sm"> Country</p>
              <p className="text-[#030229] text-sm">
                {" "}
                {
                  apllicants?.applicantDetails?.data?.customer?.subscriptionInfo
                    ?.address?.country
                }
              </p>
            </div>
            <div className="flex w-full gap-10 mt-3">
              <p className="text-[#03022980]  text-sm"> province</p>
              <p className="text-[#030229] text-sm">
                {
                  apllicants?.applicantDetails?.data?.customer?.subscriptionInfo
                    ?.address?.province
                }
              </p>
            </div>
            <div className="flex w-full gap-10 mt-3">
              <p className="text-[#03022980]  text-sm"> District</p>
              <p className="text-[#030229] text-sm">
                {
                  apllicants?.applicantDetails?.data?.customer?.subscriptionInfo
                    ?.address?.district
                }
              </p>
            </div>
            <div className="flex w-full gap-10 mt-3">
              <p className="text-[#03022980]  text-sm">Sector</p>
              <p className="text-[#030229] text-sm">
                {
                  apllicants?.applicantDetails?.data?.customer?.subscriptionInfo
                    ?.address?.sector
                }
              </p>
            </div>
            <div className="flex w-full gap-10 mt-3">
              <p className="text-[#03022980]  text-sm">Cell</p>
              <p className="text-[#030229] text-sm">
                {
                  apllicants?.applicantDetails?.data?.customer?.subscriptionInfo
                    ?.address?.cell
                }
              </p>
            </div>
            <div className="flex w-full gap-10 mt-3">
              <p className="text-[#03022980]  text-sm">Village</p>
              <p className="text-[#030229] text-sm">
                {
                  apllicants?.applicantDetails?.data?.customer?.subscriptionInfo
                    ?.address?.village
                }
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalDetailsData;
