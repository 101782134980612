import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerAction } from "../../redux/businessPlans/action";
import { updateShopStockCartDetailsAction } from "../../../../store/wareHouse/actions";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { ProgressInfobar } from "../ProgresssInfoBar";

const FinancialDetails = () => {
  const { auth, wareHouse, applicant } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { cartId } = useParams();
  const { TextArea } = Input;
  const [otherTypeOfIncome, setOtherTypeOfIncome] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [loadingStep, setLoadingStep] = useState<any>(null);

  const initialData = {
    ...wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address,
  };
  const addressData =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address;
  const witnessInfoData =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.witnessInfo;
  const userImage =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.photoCustomerHoldingID;
  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;

  const handlePrevious = async () => {
    setLoadingStep("back");
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      subscriptionStep: {
        name: "ADDRESS_DETAIL",
        index: 1,
      },
    })(dispatch);
  };

  const onFinish = async (values: any) => {
    setLoadingStep("next");
    setIsLoading(true);
    const payload = {
      ...values,
      subscriptionInfo: {
        ...values,
        address: addressData,
        witnessInfo: witnessInfoData,
        mainIncomeSource: values?.mainIncomeSource,
        occupationOrBusinessType: values?.occupationOrBusinessType,
        monthlyIncome: values?.monthlyIncome,
        incomeFrequency: values?.incomeFrequency,
        ownsSmartphone: values?.ownsSmartphone,
        nationalIDDoc: nationalId,
        photoCustomerHoldingID: userImage,
      },
    };
    const res = await updateCustomerAction(
      auth?.token,
      wareHouse?.createdCart?.data?.customer?._id,
      payload
    )(dispatch);
    if (res?.type) {
      await updateShopStockCartDetailsAction(auth?.token, cartId, {
        subscriptionStep: {
          name: "WITNESS_DETAIL",
          index: 3,
        },
      })(dispatch);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      ...wareHouse?.createdCart?.data?.customer?.subscriptionInfo,
      company: wareHouse?.createdCart?.data?.customer?.company,
    });
  }, [form, wareHouse?.createdCart?.data?.customer]);

  return (
    <>
      <div>
        <ProgressInfobar name={"Financial Details"} percentage={30} />
        <h1 className="text-[#030229] text-lg xpl-4 mb-6 font-medium text-[18px]">
          Financial Details
        </h1>
      </div>
      <Form
        form={form}
        name="register"
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialData || {}}
      >
        <div className="grid md:grid-cols-2 2xl:grid-cols-2 gap-x-4">
          <Form.Item
            name="mainIncomeSource"
            label={
              <span className="text-[#0F0F47] text-sm">
                Main Source of Income
              </span>
            }
            rules={[
              {
                required: true,
                message: "Source  of Income is required!",
                whitespace: true,
              },
            ]}
          >
            <Select
              className="border-none h-12"
              onChange={(values) =>
                values === "Other" && setOtherTypeOfIncome(true)
              }
            >
              {["Employed", "Own Business"].map((el: any) => (
                <Select.Option key={el} className="capitalize">
                  {el}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="occupationOrBusinessType"
            label={<span className="text-[#0F0F47] text-sm">Occupation</span>}
            rules={[
              {
                required: true,
                message: "Occupation is required!",
                whitespace: true,
              },
            ]}
          >
            <Select
              className="border-none h-12"
              onChange={(values) =>
                values === "Other" && setOtherTypeOfIncome(true)
              }
            >
              {[
                "Agriculture and Farming",
                "Retail and Commerce",
                "Service",
                "Manufacturing and Production",
                "Transport and Logistics",
                "Technology and IT",
                "Construction and Real Estate",
                "Health and Wellness",
                "Education and Training",
                "Art and Culture",
                "Financial Services",
                "Others",
              ].map((el: any) => (
                <Select.Option key={el} className="capitalize">
                  {el}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="grid md:grid-cols-2 2xl:grid-cols-2 gap-x-4">
          <Form.Item
            style={{ border: "none" }}
            name="company"
            label={
              <span className="text-[#0F0F47] text-[12px]">Company Name</span>
            }
            rules={[
              {
                required: true,
                message: "Name of the company is required!",
                whitespace: true,
              },
            ]}
          >
            <Input
              placeholder="Enter Company name"
              className="pl-4 h-[50px] md:h-[40px] w-[100%] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] border"
            />
          </Form.Item>
          <Form.Item
            style={{ border: "none" }}
            name="monthlyIncome"
            label={<span className="text-[#0F0F47] text-[12px]">Income</span>}
            rules={[
              {
                required: true,
                message: "Income is required!",
                whitespace: true,
              },
            ]}
          >
            <Select
              className="border-none h-12"
              onChange={(values) =>
                values === "Other" && setOtherTypeOfIncome(true)
              }
            >
              {[
                "<50k",
                "50k-100k",
                "100k-150k",
                "150k-200k",
                "200k-250k",
                "250k-300k",
                "300k-350k",
                "350k-400k",
                "400k-600k",
                "> 600k",
              ].map((el: any) => (
                <Select.Option key={el} className="capitalize">
                  {el}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="grid md:grid-cols-2 2xl:grid-cols-2 gap-x-4">
          <Form.Item
            name="incomeFrequency"
            label={
              <span className="text-[#0F0F47] text-sm">Income Frequency</span>
            }
            rules={[
              {
                required: true,
                message: "Income Frequency is required!",
                whitespace: true,
              },
            ]}
          >
            <Select
              className="border-none h-12"
              onChange={(values) =>
                values === "Other" && setOtherTypeOfIncome(true)
              }
            >
              {["Daily", "Weekly", "Monthly", "Seasonally"].map((el: any) => (
                <Select.Option key={el} className="capitalize">
                  {el}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            style={{ border: "none" }}
            name="ownsSmartphone"
            label={
              <span className="text-[#0F0F47] text-[12px]">
                Do you currently own a smartphone?
              </span>
            }
            rules={[
              {
                required: true,
                message: "Field is required!",
                whitespace: true,
              },
            ]}
          >
            <Select className="border-none h-12">
              {["Yes", "No"].map((el: any) => (
                <Select.Option key={el} className="capitalize">
                  {el}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            style={{ border: "none" }}
            name="comment"
            label={<span className="text-[#0F0F47] text-[12px]">Comment</span>}
            rules={[
              {
                whitespace: true,
              },
            ]}
          >
            <TextArea
              rows={6}
              placeholder="Comment/ TIN if compony is yours/ company location"
            />
          </Form.Item>
        </div>
        <div className="py-6 pl-4 flex-row  flex items-center justify-between  gap-4">
          <LoadingButton
            variant="outlined"
            style={{
              width: "100px",
              height: 40,
            }}
            onClick={handlePrevious}
            loading={loadingStep === "back" && wareHouse?.isUpdateFetching}
            disabled={wareHouse?.isUpdateFetching}
          >
            Previous
          </LoadingButton>
          <div className=" flex items-center gap-x-1 mr-5 2xl:gap-x-4 ">
            <LoadingButton
              type="submit"
              variant="contained"
              style={{
                padding: "10px",
              }}
              loading={
                loading ||
                (loadingStep === "next" && wareHouse?.isUpdateFetching)
              }
              disabled={loading || wareHouse?.isUpdateFetching}
            >
              {"Save and Continue"}
            </LoadingButton>
          </div>
        </div>
      </Form>
    </>
  );
};

export default FinancialDetails;
