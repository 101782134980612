import React, { useEffect, useState } from "react";
import OverViewCard from "../../../components/cards/inventory/OverViewCard";
import { Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import type { RadioChangeEvent } from "antd";
import { ReactComponent as BoxIcons } from "../../../assets/icons/dashboard/box-tick.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { TbGridDots } from "react-icons/tb";
import { GoListUnordered, GoPlus } from "react-icons/go";
import CustomButton from "../../../components/buttons/CustomButton";
import StockRequestTable from "../../../components/tables/StockRequestTable";
import { getActiveShop } from "../../../utils/converter";
import { createRequestStockAction } from "../../../store/channel/actions";
import { overViewStatActions } from "../../../store/posOverView/actions";
import { useNavigate } from "react-router-dom";

const StockRequest = () => {
  const { posOverView, auth, channel, appSettings } = useSelector(
    (state: any) => state
  );

  const [value4, setValue4] = useState("review");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  useEffect(() => {
    auth?.token && overViewStatActions(auth?.token, shopId)(dispatch);
  }, [auth?.token, dispatch, shopId]);

  const onChange4 = ({ target: { value } }: RadioChangeEvent) => {
    setValue4(value);
  };

  const options = [
    { label: "Review ", value: "review" },
    { label: "Pending Stock ", value: "request" },
    { label: "Done", value: "done", title: "done" },
    { label: "In Transit Stock ", value: "In-Transit" },
    { label: "Stock To be Returned", value: "return" },
    { label: "Approved Stock ", value: "approved", title: "approved" },
  ];

  return (
    <div className="text-black">
      <h1 className="text-[#030229d8] text-sm sm:text-base  md:text-lg xl:text-xl ">
        Stock Request
      </h1>
      <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mt-3">
        <OverViewCard
          total={
            posOverView.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.stockRequests || 0
            )
          }
          text="Total Requested"
          icon={<BoxIcons />}
        />
        <OverViewCard
          total={
            posOverView.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.totalCurrentDevices || 0
            )
          }
          text="Total Stock"
          icon={<BoxIcons />}
        />
        <OverViewCard
          total={
            posOverView.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.totalDevicesSold || 0
            )
          }
          text="Total Sold"
          icon={<BoxIcons />}
        />
        <OverViewCard
          total={
            posOverView.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.transfer || 0
            )
          }
          text="Total Transfer"
          icon={<BoxIcons />}
        />
      </div>
      <div className="mt-5 md:flex justify-between w-full">
        <div className="w-full overflow-x-auto">
          <Radio.Group
            className="mt-1 "
            options={options}
            onChange={onChange4}
            value={value4}
            optionType="button"
            buttonStyle="solid"
          />
        </div>
        <div className="flex gap-5 mt-5 md:mt-0">
        
          <CustomButton
            title={
              channel?.isFetching ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24, color: "white" }}
                      color="white"
                    />
                  }
                />
              ) : (
                "Request Stock"
              )
            }
            bgColor={"[#605BFF]"}
            textColor="white"
            onClick={async () => {
              if (auth?.token) {
                const res: any = await createRequestStockAction(auth?.token, {
                  shopId: shopId,
                  channel: auth?.user?.shop?.channel,
                })(dispatch);
                if (res) {
                  navigate(`/pos/request/${res?.requestId}`);
                }
              }
            }}
            icon={<GoPlus size={20} color="white" />}
          />
        </div>
      </div>
      <div className="mt-5 bg-white">
        <StockRequestTable value={value4} />
      </div>
    </div>
  );
};

export default StockRequest;
