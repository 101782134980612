import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "Customer-Library",
  initialState: {
    isFetching: false,
    isGettingCustomerLoan: false,
    all: { data: [] },
    selected: null,
    attributes: null,
    new: null,
    updated: null,
    query: null,
    searchResult: null,
    purchased: null,
    customerRepairs: null,
    topCustomers: null,
    customerAnalytics: null,
    customerOverview: null,
    customerStatus: null,
    recentCustomer: null,
    customerInvoice: null,
    bellingInvoice: null,
    customerNotes: null,
    customerNoSale: null,
    customerMetrics: null,
    customerLoan: null,
    customerByPhone: null,
    verifyCustomer:null
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setTopCustomers(state, action) {
      state.topCustomers = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setSearchResult(state, action) {
      state.searchResult = action.payload;
    },
    setPurchased(state, action) {
      state.purchased = action.payload;
    },
    setCustomerRepair(state, action) {
      state.customerRepairs = action.payload;
    },
    setAnalyticsCustomers(state, action) {
      state.customerAnalytics = action.payload;
    },
    setCustomerOverview(state, action) {
      state.customerOverview = action.payload;
    },
    setcustomerStatus(state, action) {
      state.customerStatus = action.payload;
    },
    setRecentcustomer(state, action) {
      state.recentCustomer = action.payload;
    },
    setCustomerInvoice(state, action) {
      state.customerInvoice = action.payload;
    },
    setBellingInvoice(state, action) {
      state.bellingInvoice = action.payload;
    },
    setCustomerNotes(state, action) {
      state.customerNotes = action.payload;
    },
    setCustomerNoSale(state, action) {
      state.customerNoSale = action.payload;
    },
    setCustomerMetrics(state, action) {
      state.customerMetrics = action.payload;
    },
    setVerifyCustomer(state, action) {
      state.verifyCustomer = action.payload;
    },
    setCustomerLoan(state, action) {
      state.customerLoan = action.payload;
    },
    setIsGettingCustomerLoan(state, action) {
      state.isGettingCustomerLoan = action.payload;
    },

    setCustomerByPhone(state, action) {
      state.customerByPhone = action.payload;
    },
  },
});

export const myCustomerActions = mySlice.actions;

export default mySlice.reducer;
