import React from "react";
import { Input } from "antd";

const NewSearchComponent = (props: any) => {
  return (
    <Input.Search
      placeholder="Search By Phone number"
      onSearch={props?.onSearch}
      onChange={props?.onChange}
      enterButton
      style={{ width: 300, backgroundColor:"white"}}
    />
  );
};

export default NewSearchComponent;
