import React, { useState } from "react";
import { Badge, Divider, Input, Space } from "antd";

import { searchValue } from "../../../../utils/setColor";
import defaultPic from "../../../../assets/nophoto.png";
import { ReactComponent as PeopleSvg } from "../../../../assets/icons/people.svg";
import { ReactComponent as NoteSvg } from "../../../../assets/icons/stickynote.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SubscriptionPage = () => {
  const { account } = useSelector((state: any) => state);

  // Helper function to ensure status is properly accessed and has a value
  const getStatus = (item: any) => {
    return item?.status || "N/A";
  };

  // Helper function for status color
  const getStatusColor = (status: any) => {
    // Assuming searchValue is a utility function, we'll implement a basic version
    // Replace this with your actual searchValue logic if different
    switch (status?.toLowerCase()) {
      case "active":
        return "green";
      case "pending":
        return "gold";
      case "suspended":
        return "red";
      default:
        return "blue";
    }
  };

  return (
    <div className="flex flex-wrap gap-4 ">
      {account?.all?.data?.map((items: any) => (
        <Badge.Ribbon
          key={items?._id}
          text={
            <span className="text-xs flex justify-center items-center w-full font-normal pt-0.5 ">
              {getStatus(items)}
            </span>
          }
          color={getStatusColor(items?.status)}
          style={{ width: "5rem", padding: "3px 1px" }}
        >
          <Link to={`/customers/billings/${items?._id}`}>
            <div className="w-full xl:w-[386px] h-[200px] bg-white rounded-[10px] border-[.1px] shadow-[1px_4px_24px_0_rgba(192,192,192,0.1)] p-3 px-4 relative">
              <div>
                {/* Image Section */}
                <div className="flex gap-3">
                  <div>
                    <img
                      src={
                        items?.productPlan?.pricelistItem?.channel?.logo ||
                        defaultPic
                      }
                      className="w-16 h-14 rounded"
                      alt={items?.customerDetails?.name || "Product"}
                    />
                  </div>
                  <div className="flex flex-col gap-2">
                    <h1 className="text-[14px] font-normal w-[14rem]">
                      {items?.customerDetails?.name?.toUpperCase() || "N/A"}
                    </h1>
                    <p className="text-[#605BFF] text-[12px] font-normal">
                      {items?.accountID || "N/A"}
                    </p>
                  </div>
                </div>

                {/* Date and Devices Section */}
                <div className="flex justify-between my-3 mt-4 font-medium">
                  <p className="text-[13px] font-normal">
                    {items?.createdAt?.slice(0, 10) || "N/A"}
                  </p>
                  <p className="text-[13px] font-normal">
                    {items?.deliveryNote?.list?.length || 0} Devices
                  </p>
                </div>

                <Divider />

                {/* Additional Info Section */}
                <div className="flex justify-between items-center mt-5 font-medium">
                  <div className="flex gap-3">
                    <div className="w-[50px] h-[30px] rounded-[6px] border-[.1px] flex justify-center items-center">
                      <Space>
                        <PeopleSvg fontSize={15} />
                        {items?.customer?.otherPhone?.length || 0}
                      </Space>
                    </div>
                    <div className="w-[50px] h-[30px] rounded-[6px] border-[.1px] flex justify-center items-center">
                      <Space>
                        <NoteSvg fontSize={15} />
                        {items?.notes?.length || 0}
                      </Space>
                    </div>
                    <div
                      className={`w-[50px] h-[30px] rounded-[6px] border-[.1px] flex justify-center items-center uppercase ${
                        items?.type === "b2b"
                          ? "bg-blue-500 text-white"
                          : items?.type === "b2c"
                          ? "bg-green-500 text-white"
                          : "bg-gray-200 text-black"
                      }`}
                    >
                      {items?.type || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </Badge.Ribbon>
      ))}
    </div>
  );
};

export default SubscriptionPage;
