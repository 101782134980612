import React, { useEffect } from "react";
import { Table, Button, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getActiveShop } from "../../utils/converter";
import { getStockForOwnAssigneeActions } from "../../store/inventory/actions";
import { IoMdArrowRoundBack } from "react-icons/io";
import CSVExportButton from "../buttons/CSVExportButton";
import { OneFreelancers_StockHeaders } from "../headers/Csv_Header";
import { getOneFreelancerStock_CSVAction } from "../../store/csvDownload/actions";

const StockForOneFreelancerTable: React.FC<any> = ({
  userId,
  onBack,
  productId,
}) => {
  const { inventory, auth, appSettings, CSV, layout } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;
  const Id = auth?.user?.profile === "freelancer" ? auth?.user?._id : userId;

  useEffect(() => {
    if (productId) {
      auth?.token &&
        getStockForOwnAssigneeActions(
          auth?.token,
          `shop=${shopId}&assignee=${Id}&product=${productId}`
        )(dispatch);
    } else {
      auth?.token &&
        getStockForOwnAssigneeActions(
          auth?.token,
          `shop=${shopId}&assignee=${Id}`
        )(dispatch);
    }
  }, [auth?.token, dispatch, shopId, productId]);

  useEffect(() => {
    if (layout?.openCSVButton && layout?.dataToExport === "EachFreelancer") {
      getOneFreelancerStock_CSVAction(
        auth?.token,
        `shop=${shopId}&assignee=${Id}`
      )(dispatch);
    }
  }, [layout?.openCSVButton]);

  const columns = [
    {
      title: "Model",
      key: "model",
      width: 150,
      render: (record: any) => <p>{record?.item?.item.product?.model}</p>,
    },
    {
      title: "Descriptions",
      key: "descriptions",
      width: 200,
      render: (record: any) => (
        <div>
          {record?.item?.item.requestedItem?.product?.specification?.map(
            (spec: any, index: any) => (
              <div key={index}>
                {spec[0]}: {spec[1]}
              </div>
            )
          )}
        </div>
      ),
    },

    {
      title: "Shop",
      key: "shop",
      width: 100,
      render: (record: any) => <p>{record?.shop}</p>,
    },
    {
      title: "Serial Number",
      key: "serialnumber",
      width: 100,
      render: (record: any) => <p>{record?.item?.item?.serialNumber}</p>,
    },
    {
      title: "Price(Rfw)",
      key: "price",
      width: 100,
      render: (record: any) => (
        <p>
          {" "}
          {record?.item?.item.requestedItem?.product?.prices?.[0]?.value ||
            "N/A"}
        </p>
      ),
    },
    {
      title: "Extended warranty",
      key: "extendedwaranty",
      width: 100,
      render: (record: any) => (
        <p>
          {record?.item?.item.requestedItem?.product?.extendedWarranty || 0}{" "}
        </p>
      ),
    },
    {
      title: "Status",
      key: "status",
      width: 100,
      render: (record: any) => {
        const status = record?.item?.status;
        let color = "default";
        if (status === "available") color = "green";
        else if (status === "pending") color = "orange";
        else if (status === "sold") color = "red";
        else if (status === "transfer") color = "blue";
        return (
          <Tag color={color} style={{ width: "60px", padding: "4px 8px" }}>
            {status}
          </Tag>
        );
      },
    },
  ];

  const stockData =inventory &&
    inventory?.freelancerStock?.data?.map((item: any) => ({
      ...item,
      key: item?._id,
    }));
  const csvData = CSV?.csvDownloadedData?.data?.map((item: any) => {
    return {
      model: item?.item?.item?.product?.model,
      shop: item?.shop,
      specification: item?.item?.item?.product?.specs?.map(
        (el: any) => el?.label + `: ` + el?.value?.join(",")
      ),
      status: item?.item?.status,
      price:
        item?.item?.item.requestedItem?.product?.prices?.[0]?.value || "N/A",
      serialNumber: item?.item?.item?.serialNumber,
      extendedWarranty:
        item?.item?.item.requestedItem?.product?.extendedWarranty || 0,
    };
  });

  return (
    <>
      {auth.user.profile?.toLowerCase() !== "freelancer" && (
        <Button
          type="primary"
          onClick={onBack}
          style={{ marginBottom: "3rem" }}
        >
          <IoMdArrowRoundBack />
        </Button>
      )}
      {auth.user.profile?.toLowerCase() === "freelancer" && (
        <div className="flex justify-end mb-5">
          <CSVExportButton
            csvHeaders={OneFreelancers_StockHeaders}
            csvData={csvData}
            filename={`${auth?.user?.names}_Stock.csv`}
            directory={"EachFreelancer"}
          />
        </div>
      )}
      <Table
        columns={columns}
        dataSource={stockData}
        size="middle"
        loading={inventory?.isFetching}
        pagination={false}
        scroll={{ x: "100%" }}
      />
    </>
  );
};

export default StockForOneFreelancerTable;
