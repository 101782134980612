import { useState } from "react";
import { Stack, IconButton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { Badge, Carousel, Modal, Space, Tag } from "antd";
import { getColorByValue, searchValue } from "../../../utils/setColor";
import DeleteModal from "../../Modals/DeleteModal";
import { deletePriceListItemAction } from "../../../store/channel/actions";
import PricePopover from "../../Modals/PricePopover";
import UpdatePriceForm from "../../forms/UpdatePriceForm";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddItem from "../../../pages/dashboard/channels/priceList/AddItems";
import ViewSubscriptionForm from "../../forms/ViewSubscriptionForm";
import { getDefaultCurrencyCode } from "../../../utils/CurrencyHelper";
import EditIcon from "@mui/icons-material/Edit";
import auth from "../../../store/auth";
import { getPricelistAction } from "../../../store/pos/actions";
// import AddItem from "../../../../pages/channels/priceList/AddItems"

const Component = (props: any) => {
  const { auth, channel, company } = useSelector((state: any) => state);
  const [openUpdatePopover, setOpenUpdatePopover] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);

  const itemPrice = props?.data?.prices
    ?.find((price: any) => price.isActive)
    ?.value?.toLocaleString();

  const handleOpenUpdatePopover = (newOpen: boolean) => {
    setOpenUpdatePopover(newOpen);
  };

  const [visible, setVisible] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const dispatch = useDispatch();

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setShowEdit(false);
  };

  const handleDeleteModalCancels = () => {
    setVisible(false);
  };

  const deleteProduct = async () => {
    const channelId = auth?.user?.shop?.channel || auth?.userShop?.channelId;

    if (auth?.token && channelId) {
      await deletePriceListItemAction(
        auth.token,
        props?.data?._id,
        `?channel=${channelId}`
      )(dispatch);
      getPricelistAction(
        auth?.token,
        `?channel=${channelId}&page=${page - 1}&limit=${limit}`
      )(dispatch);
    } else {
      console.error("Missing auth token or channel ID.");
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOnClickView = (value: any, e: any) => {
    e.preventDefault();
    setIsModalOpen(true);
  };
  const handleOnCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="rounded-md relative border border-white hover:border-gray-300 duration-500">
        <Badge.Ribbon
          color="#E1F7DE"
          className="h-7 pt-1"
          text={
            <span
              className="text-[12px] sm:text-sm md:text-base lg:text-lg"
              style={{ color: "#3AC722", width: "135px", font: "bold" }}
            >{`${itemPrice} ${getDefaultCurrencyCode(company)}`}</span>
          }
          style={{ width: "130px" }}
        >
          <Badge.Ribbon
            color="#EDEDFE"
            className="h-7 pt-1 mt-10 w-34"
            text={
              <span
                className="text-[12px] sm:text-sm md:text-base lg:text-lg"
                style={{ color: "#605BFF" }}
              >
                {" "}
                {props?.data?.extendedWarranty
                  ? `Warranty ${props?.data?.extendedWarranty}% `
                  : ""}
              </span>
            }
            style={{ width: "130px" }}
          >
            <div className="relative w-c80 h-60 p-2 dpt-10 bg-white text-black rounded-md">
              <div className="w-fit">
                <div style={{ width: "5rem" }}>
                  <Carousel autoplay>
                    {props?.data?.product?.images?.map((im: any) => (
                      <div>
                        <img src={im} width={"100%"} alt="" />
                      </div>
                    ))}
                  </Carousel>
                </div>
                <div className="pt-3 pl-3 pb-2 py-2">
                  <div className="font-poppins text-base font-semibold leading-6 tracking-wider text-left">
                    {props?.data?.product?.model}
                  </div>
                  <div className=" gap-1">
                    {props?.data &&
                      props?.data?.specification?.map(
                        (data: any, _index: number) =>
                          data[1] && (
                            <Stack>
                              <p className="text-sm md:text-base">
                                {data[0]}:
                                <span className="text-gray-500 text-sm md:text-base">{` ${data[1]}`}</span>
                              </p>
                            </Stack>
                          )
                      )}
                  </div>
                </div>
              </div>
              <div className="absolute bottom-1 right-2 flex justify-end items-center w-full py-2 space-x-2z">
                {["sales-agent"].includes(auth?.user?.role?.toLowerCase()) &&
                  props?.data?.hasSubs && (
                    <Tag
                      color={searchValue("B2C")}
                      className="cursor-pointer"
                      onClick={(e: any) => handleOnClickView(props?.data, e)}
                    >
                      {"B2C"}
                    </Tag>
                  )}
                {["admin", "dev"].includes(auth?.user?.role?.toLowerCase()) && (
                  <>
                    <PricePopover
                      title="Update price"
                      handleOpen={handleOpenUpdatePopover}
                      open={openUpdatePopover}
                      disable={false}
                      content={
                        <UpdatePriceForm
                          handleOpenUpdatePopover={handleOpenUpdatePopover}
                          priceListItemId={props?.data?._id}
                          dataToUpdate={{
                            value: parseInt(itemPrice?.split(",")?.join("")),
                            extendedWarranty: props?.data?.extendedWarranty,
                          }}
                        />
                      }
                      icon={<EditIcon />}
                    />
                    <IconButton
                      aria-label="fingerprint"
                      color="error"
                      size="small"
                      onClick={handleClickDelete}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
          </Badge.Ribbon>
        </Badge.Ribbon>
      </div>
      <Modal
        title={"Edit Pricelist Item"}
        open={showEdit}
        onCancel={handleCancel}
        footer={null}
        className="min-w-min"
        // icon={props?.icon || <HomeIcon />}
      >
        <div>
          <div className="w-[100vh]">
            {
              <AddItem
                selectedModelId={props?.data?._id}
                onCancel={handleCancel}
              />
            }
          </div>
        </div>
      </Modal>
      {["admin", "dev"].includes(auth?.user?.role?.toLowerCase()) && (
        <DeleteModal
          visible={visible}
          onOk={deleteProduct}
          isLoading={channel.isFetching}
          onCancel={handleDeleteModalCancels}
          itemName="Product"
        />
      )}

      <Modal
        open={isModalOpen}
        onCancel={handleOnCancel}
        key={isModalOpen ? "modal-open" : "modal-closed"}
        footer={null}
        className="min-w-max"
      >
        <ViewSubscriptionForm data={props?.data} />
      </Modal>
    </>
  );
};

export default Component;
