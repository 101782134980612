import { Carousel, Radio, RadioChangeEvent } from "antd";
import React, { useEffect, useState } from "react";
import { Progressbar } from "../Progressbar";
import { useDispatch, useSelector } from "react-redux";
import { getAllPlansAction } from "../../redux/planManagement/actions";
import defaultPic from "../../../../../assets/nophoto.png";
import { updateShopStockCartDetailsAction } from "../../../../../store/wareHouse/actions";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { getSpecificationValue } from "../../../../../utils/converter";
import { CurstomeCardSkeleton } from "../../../../../components/skeleton/Card";
import { getDefaultCurrencyCode } from "../../../../../utils/CurrencyHelper";
import Okmark from "../../../../../assets/okmark.png";

const MatchingPLan = () => {
  const { auth, planManagement, wareHouse, company } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const { cartId } = useParams();
  const [selectePlan, setselectePlan] = useState("monthly");
  const [loadingPlanId, setLoadingPlanId] = useState<string | null>(null);
  const [loadingStep, setLoadingStep] = useState<any>(null);

  const channelId = auth?.user?.shop?.channel || auth?.userShop?.channelId;

  const businessProduct = wareHouse?.createdCart?.data?.businessProduct?.name;
  const selectedPlanId = wareHouse?.createdCart?.data?.selectedPlan?._id;

  const handlePrevious = async () => {
    setLoadingStep("back");
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      subscriptionStep: {
        name: "UPLOAD_DOCS",
        index: 2,
      },
    })(dispatch);
  };

  const handleNextStep = async () => {
    setLoadingStep("next");
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      subscriptionStep: {
        name: "OVERVIEW",
        index: 4,
      },
    })(dispatch);
  };

  const onChangePlan = (e: RadioChangeEvent) => {
    setselectePlan(e.target.value);
  };

  useEffect(() => {
    getAllPlansAction(
      auth?.token,
      `?businessProductType=${businessProduct}&channel=${channelId}`
    )(dispatch);
  }, [auth?.token, dispatch, businessProduct, channelId]);

  const handleChoosePlan = async (value: any) => {
    setLoadingPlanId(value?._id);
    const res = await updateShopStockCartDetailsAction(auth?.token, cartId, {
      selectedPlan: value?._id,
      subscriptionPlan: value?.duration,
      instalmentPlan: selectePlan,
      other: {
        bundle: value?.bundle?.at(0),
        call: value?.call?.at(0),
        sms: value?.sms?.at(0),
        period: "12",
      },
    })(dispatch);
    if (res) {
      setLoadingPlanId(null);
    }
  };

  return (
    <div className="h-full">
      <Progressbar name={"Matching Plans"} percentage={80} />
      <div className="w-full border px-6 pt-4 rounded-lg bg-white h-full">
        <h1 className="text-xl font-medium mb-2 2xl:mb-6">
          Recommended devices
        </h1>

        <div className="lg:flex gap-6 py-4">
          <div className="w-full lg:w-[15rem] 2xl:w-[26rem] py-4 px-6 rounded-md border-2 shadow-md">
            <h2 className="text-lg font-semibold mb-4">Filter:</h2>
            <div className=" flex justify-between lg:block">
              {/* <div className="mb-6">
              <h3 className="font-medium mb-2">By Brands</h3>

              <Radio.Group
                onChange={onChange}
                value={value}
                className="flex flex-col ml-2"
              >
                <Radio value={1}>All Brands</Radio>
                <Radio value={2}>iPhone</Radio>
                <Radio value={3}>Infinix</Radio>
                <Radio value={4}>Samsung</Radio>
              </Radio.Group>
            </div> */}
              <div>
                <h3 className="font-medium mb-2">By Installment Plan</h3>
                <Radio.Group
                  onChange={onChangePlan}
                  value={selectePlan}
                  className="flex flex-col ml-2"
                  defaultValue={"month"}
                >
                  {/* <Radio value={"daily"}>Daily</Radio>
                <Radio value={"weekly"}>Weekly</Radio> */}
                  <Radio value={"monthly"}>Monthly</Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
          <div className="flex-1 h-full xl:h-[calc(100vh-380px)] overflow-y-auto">
            {planManagement?.isFetching ? (
              <div className="grid mt-5 lg:mt-0 grid-cols-2 2xl:grid-cols-3">
                {[1, 2, 3, 1, 2, 3]?.map((el: any) => (
                  <CurstomeCardSkeleton />
                ))}
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 mt-5 lg:mt-0 2xl:grid-cols-3 gap-4">
                {planManagement?.all?.data
                  ?.filter((el: any) => el?.duration)
                  ?.map((plan: any) => {
                    const capacity = getSpecificationValue(
                      plan?.pricelistItem?.specification,
                      ["capacity (rom)", "rom", "capacity"]
                    );
                    const memory = getSpecificationValue(
                      plan?.pricelistItem?.specification,
                      ["memory", "memory (ram)", "ram", "memory(ram)"]
                    );

                    const price =
                      selectePlan === "daily"
                        ? plan?.dailyInstalment
                        : selectePlan === "weekly"
                        ? plan?.weeklyInstalment
                        : plan?.monthlyInstalment;
                    const isSelected = plan?._id === selectedPlanId;

                    return (
                      <div className="border-2 rounded-xl shadow p-6 text-center bg-white mr-4">
                        <div className="flex flex-col h-full">
                          <div className="flex-1">
                            <div className="flex items-center gap-x-3">
                              <div style={{ width: "6rem" }}>
                                <Carousel autoplay arrows>
                                  {plan?.pricelistItem?.product?.images
                                    ?.length > 0 ? (
                                    plan?.pricelistItem?.product?.images?.map(
                                      (el: any) => (
                                        <img
                                          src={el}
                                          alt={`images`}
                                          className="w-24 h-24 object-cover"
                                        />
                                      )
                                    )
                                  ) : (
                                    <img
                                      src={defaultPic}
                                      alt={`images`}
                                      className="w-24 h-24 object-cover"
                                    />
                                  )}
                                </Carousel>
                              </div>

                              <div className="text-start">
                                <h1 className="text-xl font-semibold mb-2">
                                  {plan?.pricelistItem?.product?.model}
                                </h1>
                                <div className="text-left">
                                  <p className="text-sm text-gray-500 capitalize">
                                    <span className="text-black">Brand: </span>{" "}
                                    {plan?.pricelistItem?.product?.brand}
                                  </p>
                                  <p className="text-sm text-gray-500">
                                    <span className="text-black">
                                      Capacity(ROM):{" "}
                                    </span>{" "}
                                    {capacity}
                                  </p>
                                  <p className="text-sm text-gray-500 mb-4">
                                    <span className="text-black">
                                      Memory(RAM):{" "}
                                    </span>{" "}
                                    {memory}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="text-left mb-4">
                              <h4 className="text-sm font-medium mb-2">
                                Includes:
                              </h4>
                              <ul className="space-y-2">
                                <li className="flex items-center">
                                  <div className="mr-1 bg-[#efeeff] rounded-full p-1.5">
                                    <svg
                                      className="flex-shrink-0 w-3 h-3 text-blue-500 "
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 16 12"
                                    >
                                      <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M1 5.917 5.724 10.5 15 1.5"
                                      />
                                    </svg>
                                  </div>
                                  <p
                                    className={`${
                                      plan?.bundle?.at(0) === undefined &&
                                      "line-through"
                                    } text-sm text-gray-700`}
                                  >
                                    Bundles:{" "}
                                    {`${
                                      plan?.bundle?.at(0) || "0"
                                    }  / Per Month`}
                                  </p>
                                </li>
                                <li className="flex items-center">
                                  <div className="mr-1 bg-[#efeeff] rounded-full p-1.5">
                                    <svg
                                      className="flex-shrink-0 w-3 h-3 text-blue-500 "
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 16 12"
                                    >
                                      <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M1 5.917 5.724 10.5 15 1.5"
                                      />
                                    </svg>
                                  </div>
                                  <p
                                    className={`${
                                      plan?.bundle?.at(0) === undefined &&
                                      "line-through"
                                    } text-sm text-gray-700`}
                                  >
                                    SMS:{" "}
                                    {`${plan?.sms?.at(0) || "0"} / Per Month`}
                                  </p>
                                </li>
                                <li className="flex items-center">
                                  <div className="mr-1 bg-[#efeeff] rounded-full p-1.5">
                                    <svg
                                      className="flex-shrink-0 w-3 h-3 text-blue-500 "
                                      aria-hidden="true"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 16 12"
                                    >
                                      <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M1 5.917 5.724 10.5 15 1.5"
                                      />
                                    </svg>
                                  </div>
                                  <p
                                    className={`${
                                      plan?.bundle?.at(0) === undefined &&
                                      "line-through"
                                    } text-sm text-gray-700`}
                                  >
                                    Airtimes:{" "}
                                    {`${plan?.call?.at(0) || "0"} / Per Month`}
                                  </p>
                                </li>
                              </ul>
                            </div>

                            <div className="grid grid-cols-2 text-start xitems-baseline mb-4">
                              <div className="pl-2">
                                <p className="text-base font-semibold">{`${plan?.initialPayment?.toLocaleString()} (${getDefaultCurrencyCode(
                                  company
                                )})`}</p>
                                <span>Upfront</span>
                              </div>
                              <div className="border-r pr-1">
                                <p className="text-base font-semibold">{`${price?.toLocaleString()} (${getDefaultCurrencyCode(
                                  company
                                )})`}</p>
                                <span className="capitalize">{`/${selectePlan}`}</span>
                              </div>
                            </div>
                            <p className="text-sm text-gray-500 mb-4 text-start">
                              <span className="font-semibold pr-2">{`${
                                plan?.duration ?? "N/A"
                              } Months`}</span>
                              Duration
                            </p>
                          </div>

                          <div className="h-10">
                            <LoadingButton
                              loading={
                                wareHouse?.isUpdateFetching &&
                                loadingPlanId === plan?._id
                              }
                              disabled={wareHouse?.isUpdateFetching}
                              sx={{
                                height: 40,
                                background: isSelected ? "" : "#f0efff",
                                color: "blue",
                              }}
                              className="mt-4 w-48 transition"
                              onClick={() => {
                                handleChoosePlan(plan);
                              }}
                            >
                              {isSelected ? (
                                <img
                                  src={Okmark}
                                  alt=""
                                  className="h-10 w-10"
                                />
                              ) : (
                                " Choose plan"
                              )}
                            </LoadingButton>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-2">
        <LoadingButton
          variant="outlined"
          style={{
            width: "150px",
            height: 40,
          }}
          onClick={handlePrevious}
          loading={loadingStep === "back" && wareHouse?.isUpdateFetching}
          disabled={wareHouse?.isUpdateFetching}
        >
          {`Previous`}
        </LoadingButton>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleNextStep}
          style={{
            padding: "7px",
            height: 40,
          }}
          className="w-[150px] h-[45px] text-lg uppercase "
          disabled={
            !wareHouse?.createdCart?.data?.selectedPlan ||
            wareHouse?.isUpdateFetching
          }
          loading={loadingStep === "next" && wareHouse?.isUpdateFetching}
        >
          {"Next"}
        </LoadingButton>
      </div>
    </div>
  );
};

export default MatchingPLan;
