import { myEbmActions } from ".";
import { myWareHouseActions } from "../wareHouse";
import { getShopStockCart } from "../wareHouse/services";
import {
  createItemsService,
  createSalesServices,
  getAllItemsService,
  getAllOptionsService,
} from "./services";

export const createItemsAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEbmActions.setIsFetching(true));
      const res = await createItemsService(token, data);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 201) {
        const resAll = await getAllItemsService(token, "?");
        dispatch(myEbmActions.setNew(res));
        dispatch(myEbmActions.setManageItem(resAll));
        dispatch(myEbmActions.setIsFetching(false));
      }
      dispatch(myEbmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllOptionsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEbmActions.setIsFetching(true));
      const res = await getAllOptionsService(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myEbmActions.setOptions(res));
        dispatch(myEbmActions.setIsFetching(false));
      }
      dispatch(myEbmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllItemsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEbmActions.setIsFetching(true));
      const res = await getAllItemsService(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myEbmActions.setManageItem(res));
        dispatch(myEbmActions.setIsFetching(false));
      }
      dispatch(myEbmActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const createSalesActions = (
  token: string,
  data: any,
  cartId?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myEbmActions.setIsFetching(true));
      const res = await createSalesServices(token, data);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 201) {
        const getCart = await getShopStockCart(token, cartId);
        dispatch(myWareHouseActions.setCreatedCart(getCart));
        dispatch(myEbmActions.setNewSale(res));
        // dispatch(myEbmActions.setManageItem(resAll));
        dispatch(myEbmActions.setIsFetching(false));
        return true;
      } else {
        dispatch(myEbmActions.setIsFetching(false));
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };
};
