import React, { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { useSelector, useDispatch } from "react-redux";
import LoadingAnimation from "../../../assets/images/circle-loading-lines.gif";
import SuccessAnimation from "../../../assets/images/successfully-done.gif";
import { MdError } from "react-icons/md";
import { checkRepaymentStatusAction } from "../../../store/pos/actions";
import { getDefaultCurrencyCode } from "../../../utils/CurrencyHelper";
import { myPosActions } from "../../../store/pos";
import { useParams } from "react-router-dom";
import { getCustomerLoanAction } from "../../../store/customer/actions";
import { myLayoutActions } from "../../../store/layout";
import { Button } from "antd";

const MomoWaitingAndSuccess = () => {
  const { company, pos, layout, customer } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { customerId } = useParams();

  const paymentStatus = async () => {
    if (pos?.repaymentReferenceId) {
      await checkRepaymentStatusAction(
        `?referenceId=${pos?.repaymentReferenceId}&accountId=${layout?.selectedInvoiceToPay?.account}`
      )(dispatch);
    }
  };

  const currentRepaymentStatus = pos?.repaymentStatusResult?.data?.status;

  const handleTryAgain = () => {
    dispatch(myLayoutActions.setOpenRepaymentModel(true));
    dispatch(myPosActions.setOpenWaitingModal(false));
    dispatch(myPosActions.setRepaymentStatusResult(null));
  };

  const handleComplete = () => {
    dispatch(myLayoutActions.setOpenRepaymentModel(false));
    dispatch(myPosActions.setOpenWaitingModal(false));
    dispatch(myLayoutActions.setSelectedInvoiceToPay(null));
  };

  useEffect(() => {
    if (!pos.repaymentInitiationStatus) {
      dispatch(myPosActions.setRepaymentStatusResult(null));
      return;
    }

    let timerId: any = null;
    if (currentRepaymentStatus?.toLowerCase() === "failed") {
      return;
    } else {
      timerId = setInterval(async () => {
        if (!layout?.isRequestDone) {
          await paymentStatus();
        }
      }, 7000);
    }

    if (currentRepaymentStatus?.toLowerCase() === "successful") {
      clearInterval(timerId);
      getCustomerLoanAction(customerId as string)(dispatch);
    }

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentRepaymentStatus,
    pos?.repaymentInitiationStatus,
    layout?.isRequestDone,
  ]);

  return (
    <div className="flex justify-center items-center flex-col h-full max-w-[100%]">
      {currentRepaymentStatus?.toLowerCase() === "failed" ? (
        <div className="flex justify-center items-center flex-col w-fit">
          <MdError className="h-16 w-16 text-red-500" />
          <div className="flex flex-col text-center">
            <p className="text-base">This operation failed</p>
            <p className="text-sm">
              Make sure the customer have sufficient funds
            </p>
          </div>
          <Button
            type="primary"
            onClick={handleTryAgain}
            style={{ minWidth: 50 }}
            className="mt-4 mb-2 flex items-center text-center"
          >
            Try Again
          </Button>
        </div>
      ) : (
        <>
          {currentRepaymentStatus?.toLowerCase() !== "successful" ? (
            <>
              <p className="lg:text-lg md:text-sm font-semibold">
                Complete Payment of RWF {layout?.amountToPay?.toLocaleString()}
              </p>
              <p>
                If there is no popup on your phone press *182*7*1# and confirm
                payment
              </p>
            </>
          ) : (
            <div className="text-center">
              <p className="lg:text-lg md:text-sm ">
                You have successfuly completed payment of
              </p>
              <p className="font-semibold">
                {" "}
                {`${getDefaultCurrencyCode(
                  company
                )} ${layout?.amountToPay?.toLocaleString()}`}
              </p>
            </div>
          )}
          {currentRepaymentStatus?.toLowerCase() === "successful" ? (
            <>
              <img
                width="20%"
                height="20%"
                src={SuccessAnimation}
                alt="Successful"
              />
              <Button
                type="primary"
                style={{ minWidth: 50 }}
                onClick={handleComplete}
                className="mt-4 mb-2 flex items-center text-center"
                loading={
                  pos?.isUpdatingInvoice || customer?.isGettingCustomerLoan
                }
                disabled={
                  pos?.isUpdatingInvoice || customer?.isGettingCustomerLoan
                }
              >
                Finish
              </Button>
            </>
          ) : (
            <img
              width="20%"
              height="20%"
              src={LoadingAnimation}
              alt="loading"
            />
          )}
        </>
      )}
    </div>
  );
};

export default MomoWaitingAndSuccess;
