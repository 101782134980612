import { LinearProgress } from "@mui/material";
import { Progress } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import BackButton from "../../../components/buttons/BackButton";

export const ProgressInfobar: React.FC<{
  name: string;
  percentage: number;
  goBack?: any;
}> = ({ name, percentage, goBack }) => {
  const { wareHouse } = useSelector((state: any) => state);
  const currentStep = wareHouse?.createdCart?.data?.subscriptionStep?.index;
  return (
    <div className="pb-5">
      <div className="flex flex-row justify-between pb-2">
        <div className="flex gap-x-2">
          {goBack && <BackButton onClick={goBack} />}
          <h1 className="font-semibold text-[20px]">{name}</h1>
        </div>
        <div>
          <span className="text-[#3e38f5]">Step {currentStep + 1}</span> of 7
        </div>
      </div>
      <LinearProgress variant="determinate" value={percentage} />
    </div>
  );
};

export const CircularGauge: React.FC<{
  targetValue: any;
}> = ({ targetValue }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev < targetValue) {
          return prev + 1;
        } else {
          clearInterval(interval);
          return targetValue;
        }
      });
    }, 20);

    return () => clearInterval(interval);
  }, [targetValue]);

  return (
    <div style={{ width: 100, height: 200 }}>
      <Progress type="circle" percent={progress} />
    </div>
  );
};
