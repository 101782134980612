import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { DatePicker, Form, Input, Modal, Button } from "antd";
import dayjs from "dayjs";
import type { FormInstance } from "antd/es/form";

interface Customer {
  customerByPhone?: {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    _id: string;
  };
}

interface VerificationModalProps {
  isVerifyModal: boolean;
  handleCancel: () => void;
  customer: Customer;
  onVerificationSuccess: () => void;
  form: FormInstance;
}

type VerificationMethodKey = 'firstName' | 'lastName' | 'dateOfBirth';

interface VerificationMethod {
  key: VerificationMethodKey;
  label: string;
  component: (maskedValue: string) => React.ReactNode;
}

const VerificationModal: React.FC<VerificationModalProps> = ({ 
  isVerifyModal, 
  handleCancel, 
  customer, 
  onVerificationSuccess,
  form 
}) => {
  const [verificationMethod, setVerificationMethod] = useState<VerificationMethodKey | null>(null);

  const maskValue = (value: string): string => {
    if (!value) return "";
    return value.substring(0, 2) + "*".repeat(value.length - 2);
  };

  const getMaskedValues = () => ({
    firstName: maskValue(customer?.customerByPhone?.firstName || ""),
    lastName: maskValue(customer?.customerByPhone?.lastName || ""),
    dateOfBirth: customer?.customerByPhone?.dateOfBirth 
      ? maskValue(dayjs(customer?.customerByPhone?.dateOfBirth).format("YYYY"))
      : ""
  });
  
  const verificationMethods: VerificationMethod[] = [
    {
      key: 'firstName',
      label: 'First Name',
      component: (maskedValue: string) => (
        <Form.Item
          name="firstName"
          label={<span className="opacity-70 text-[16px]">Enter First Name:</span>}
          initialValue={maskedValue}
          rules={[
            {
              validator: async (_: any, value: string) => {
                if (!value) return Promise.reject('Please enter first name');
                return value === customer?.customerByPhone?.firstName
                  ? Promise.resolve()
                  : Promise.reject('First name does not match!');
              },
            },
          ]}
        >
          <Input
            style={{
              height: "50px",
              width: "100%",
              border: "1px solid #03022930",
              borderRadius: "10px",
            }}
            placeholder="Enter your first name"
          />
        </Form.Item>
      )
    },
    {
      key: 'lastName',
      label: 'Last Name',
      component: (maskedValue: string) => (
        <Form.Item
          name="lastName"
          label={<span className="opacity-70 text-[16px]">Enter Last Name:</span>}
          initialValue={maskedValue}
          rules={[
            {
              validator: async (_: any, value: string) => {
                if (!value) return Promise.reject('Please enter last name');
                return value === customer?.customerByPhone?.lastName
                  ? Promise.resolve()
                  : Promise.reject('Last name does not match!');
              },
            },
          ]}
        >
          <Input
            style={{
              height: "50px",
              width: "100%",
              border: "1px solid #03022930",
              borderRadius: "10px",
            }}
            placeholder="Enter your last name"
          />
        </Form.Item>
      )
    },
    {
      key: 'dateOfBirth',
      label: 'Year of Birth',
      component: (maskedValue: string) => (
        <>
          <div className="mb-2">
            <span className="text-[#0F0F47] text-[16px] block">Your birth year: {maskedValue}</span>
          </div>
          <Form.Item
            name="dateOfBirth"
            className="w-[100%]"
            label={<span className="text-[#0F0F47] text-[16px]">Enter your year of birth:</span>}
            rules={[
              {
                validator: async (_: any, value: any) => {
                  if (!value) return Promise.reject('Please select year of birth');
                  const isValidDate = dayjs(value).isValid();
                  const yearMatches = dayjs(value).format("YYYY") === 
                    dayjs(customer?.customerByPhone?.dateOfBirth).format("YYYY");
                  return isValidDate && yearMatches
                    ? Promise.resolve()
                    : Promise.reject('Year of birth does not match!');
                },
              },
            ]}
          >
            <DatePicker 
              format="YYYY" 
              className="w-[100%] h-[50px]"
              placeholder="Select year of birth"
            />
          </Form.Item>
        </>
      )
    }
  ];

  // Reset and randomize verification method when modal opens
  useEffect(() => {
    if (isVerifyModal) {
      form.resetFields();
      setVerificationMethod(null);
      const randomIndex = Math.floor(Math.random() * verificationMethods.length);
      setVerificationMethod(verificationMethods[randomIndex].key);
    }
  }, [isVerifyModal, form]);

  // Handle field values when method changes
  useEffect(() => {
    if (verificationMethod && customer?.customerByPhone) {
      const maskedValues = getMaskedValues();
      if (verificationMethod !== 'dateOfBirth') {
        form.setFieldsValue({
          [verificationMethod]: maskedValues[verificationMethod]
        });
      }
    }
  }, [verificationMethod, customer?.customerByPhone, form]);

  const handleMethodChange = (): void => {
    form.resetFields();
    // Get all methods except current one
    const availableMethods = verificationMethods.filter(m => m.key !== verificationMethod);
    // Randomly select from remaining methods
    const randomIndex = Math.floor(Math.random() * availableMethods.length);
    setVerificationMethod(availableMethods[randomIndex].key);
  };

  const handleFinish = async (values: Record<string, any>): Promise<void> => {
    try {
      await form.validateFields();
      onVerificationSuccess();
    } catch (error) {
      // Form validation will handle showing errors
    }
  };

  const maskedValues = getMaskedValues();

  return (
    <Modal
      title="Verify Customer"
      open={isVerifyModal}
      footer={null}
      onCancel={() => {
        handleCancel();
        form.resetFields();
        setVerificationMethod(null);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        className="w-full md:w-[450px]"
      >
        {customer?.customerByPhone && (
          <>
            <div className="mt-6">
              {verificationMethod && 
                verificationMethods
                  .find(m => m.key === verificationMethod)
                  ?.component(maskedValues[verificationMethod])}
              
              <div className="flex justify-end mb-4">
                <Button 
                  type="link" 
                  onClick={handleMethodChange}
                  className="text-[#605BFF]"
                >
                  Try different verification method
                </Button>
              </div>
            </div>
            
            <div className="px-8 flex justify-center items-center">
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{
                  minWidth: "100%",
                  backgroundColor: "#605BFF",
                  "&:hover": {
                    backgroundColor: "#4E4ACF",
                  },
                  height: "40px",
                  borderRadius: "5px",
                }}
                loading={false}
              >
                Verify customer
              </LoadingButton>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default VerificationModal;
