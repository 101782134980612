/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef, ReactInstance } from "react";
import { useDispatch, useSelector } from "react-redux";
import Aos from "aos";
import "aos/dist/aos.css";
import { Checkbox, Divider } from "antd";
import { useReactToPrint } from "react-to-print";
import SignatureCanvas from "react-signature-canvas";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { sendOTPtoCustomerAction } from "../../../device_financing/Subscription/redux/otpManagement/actions";
import {
  getShopStockCartAction,
  updateShopStockCartDetailsAction,
} from "../../../../store/wareHouse/actions";
import { Progressbar } from "../../../device_financing/Subscription/components/Progressbar";
import CustomModel from "../../../device_financing/Subscription/components/models/CustomModel";
import Signature from "../Signature";
import { ProgressInfobar } from "../ProgresssInfoBar";
import { getOneCompanyAction } from "../../../../store/company/actions";
import { COMPANY_PROFILE_ID } from "../../../../utils/constants";

const ConsentAgreementPage = (props: any) => {
  const { wareHouse, customer, posYelllow, otpManagement, company } =
    useSelector((state: any) => state);
  const [openSignature, setOpenSignature] = React.useState(false);
  const handleOpen = () => setOpenSignature(true);
  const handleClose = () => setOpenSignature(false);
  const dispatch = useDispatch();
  const [openOtp, setOpenOtp] = React.useState(false);
  const handleCloseOtp = () => setOpenOtp(false);
  const templateRef = useRef<ReactInstance>(null);
  const [sign, setSign] = useState<SignatureCanvas | null>(null);
  const { auth, pos } = useSelector((state: any) => state);
  const { cartId } = useParams();
  const [checked, setChecked] = useState(
    wareHouse?.createdCart?.data?.receiptSignature ? true : false
  );
  const [loadingStep, setLoadingStep] = useState<any>(null);

  const customerData = wareHouse?.createdCart?.data?.customer;
  const handlePrevious = async () => {
    setLoadingStep("back");
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      subscriptionStep: {
        name: "WITNESS_DETAIL",
        index: 3,
      },
    })(dispatch);
  };

  const handleNextStep = async () => {
    setLoadingStep("next");
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      subscriptionStep: {
        name: "SELECT_PLAN",
        index: 5,
      },
    })(dispatch);
  };

  const handleClear = () => {
    sign?.clear();
  };

  const handlePrint = useReactToPrint({
    content: () => templateRef.current,
    pageStyle: `
        @media print {
          @page {
            size: 58mm auto;
            margin: 0;
          }
        }
        `,
  });

  useEffect(() => {
    Aos.init();
  }, []);

  const onChange = (e: any) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    if (auth?.token && cartId) {
      getShopStockCartAction(auth?.token, cartId)(dispatch);
    }
  }, [auth?.token, cartId, dispatch]);
  const companyId = COMPANY_PROFILE_ID;

  useEffect(() => {
    if (auth?.token && companyId) {
      getOneCompanyAction(companyId as string, auth.token)(dispatch);
    }
  }, [auth.token, dispatch, companyId]);
  return (
    <div className="h-[82vh] overflow-y-auto text-[#030229]">
      <ProgressInfobar name={"Consent Agreements"} percentage={70} />
      <div className="w-full flex flex-col relative space-y-4 px-6 text-[16px] leading-relaxed">
        <div>
          <center className=" px-10 font-semibold uppercase text-base">
            CONSENT FORM
          </center>
        </div>
        <div className="pt-4 flex items-center text-center  flex-col space-y-4">
          <p>
            This consent form <b>(CONSENT FORM)</b> made on{" "}
            <b>{customerData?.createdAt?.slice(0, 10)}</b> , by and between:
          </p>
          <p>
            <span className="font-medium">Consentee : </span>
            <b>{customerData?.name}</b> with a mailing address of{" "}
            <b>{customerData?.email}</b> <b>{customerData?.name}</b> hereby
            consents and gives permission to:
          </p>
          <p>
            <span className="font-medium">Releasee: </span>
            <b>{company?.selected?.name}</b> with a mailing address of{" "}
            <b>{company?.selected?.email}</b>
            <b>{company?.selected?.name}</b> to perform the following acts
            mentioned herein:
          </p>
          <p>
            <span className="font-medium">PERMISSABLE ACTS. </span>
            The Releasee, Samphone, is granted permission to use the
            Consentee&#39;s personal information for the purpose of performing a
            credit scoring assessment to determine eligibility for device
            financing.
          </p>
          <p>
            <span className="font-medium">TERM. </span>
            The permission granted by this Form will remain in effect until:
          </p>
          <div className="flex flex-col items-center">
            <p>
              <span className="font-medium">- A Specific Date. </span>
              Until the date of [DATE].
            </p>
            <p>
              <span className="font-medium">
                - Until the Consentee Cancels.{" "}
              </span>
              Until the Consentee revokes this Form.
            </p>
            <p>
              <span className="font-medium">- Other. </span> [DESCRIBE].
            </p>
          </div>
        </div>

        <div className="pt-4 flex items-center justify-center text-center">
          <div className="flex mx-auto items-center text-center">
            <p>
              <span className="font-medium">DISCLOSURE. </span>
              The Consentee agrees to release and hold harmless Samphone, along
              with its agents, employees, successors, and assigns, from any and
              all legal, financial, or other liabilities, including but not
              limited to any claims, demands, damages, actions, or causes of
              action arising from or related to the permissible acts. This
              release extends to any future injuries or damages, whether to
              person or property, that may result from or relate to the actions
              performed by Samphone as authorized under this Form.
            </p>
          </div>
        </div>
        <Divider dashed />
      </div>

      <div className="p-5">
        <Checkbox checked={checked} onChange={onChange}>
          I agree{" "}
          <span className="text-blue-500 font-medium">Consent Form</span>
        </Checkbox>
      </div>

      <div className="py-6 pl-4 flex-row  flex items-center justify-between  gap-4">
        <LoadingButton
          variant="outlined"
          style={{
            width: "100px",
            height: 40,
          }}
          onClick={handlePrevious}
          loading={loadingStep === "back" && wareHouse?.isUpdateFetching}
          disabled={wareHouse?.isUpdateFetching}
        >
          Previous
        </LoadingButton>
        <div className=" flex items-center gap-x-1 mr-5 2xl:gap-x-4 ">
          {wareHouse?.createdCart?.data?.receiptSignature ? (
            <LoadingButton
              variant="contained"
              style={{
                height: 40,
              }}
              onClick={handleNextStep}
              loading={loadingStep === "next" && wareHouse?.isUpdateFetching}
              disabled={wareHouse?.isUpdateFetching}
            >
              <p className="text-sm uppercase">Next</p>
            </LoadingButton>
          ) : (
            <LoadingButton
              variant="outlined"
              disabled={!checked}
              loading={
                customer?.isFetching ||
                wareHouse?.isFetching ||
                posYelllow?.isFetching ||
                pos?.isFetching
              }
              style={{
                height: 40,
              }}
              onClick={handleOpen}
            >
              <p className="text-sm uppercase">Confirm By Signature</p>
            </LoadingButton>
          )}
        </div>
      </div>
      <CustomModel
        width={700}
        isOpen={openSignature}
        onClose={handleClose}
        contents={
          <div className="flex justify-center items-center h-full rounded-md">
            <div className="bg-white px-4 py-4 w-[100%]">
              <Signature
                handleClose={handleClose}
                handlePrint={handlePrint}
                from={"receiptSignature"}
                // checkSignature={checkSignature}
                handleClear={handleClear}
                setSign={setSign}
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ConsentAgreementPage;
