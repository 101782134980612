import React, { useState } from "react";
import UpdatePasswordForm from "../../components/forms/UpdatePassword";
import { useDispatch, useSelector } from "react-redux";
import { getRecentPurchaseOrdersAction } from "../../store/purchase/actions";
import {
  getRecentPosOrdersAction,
  getShopCommissionBalanceAction,
} from "../../store/pos/actions";
import {
  calculatePercentageChange,
  getActiveShop,
} from "../../utils/converter";
import moment from "moment";
import TotalRevenueAndDataActivation from "../../components/cards/dashboard/Sections/TotalRevenueAndDataActivation";
import {
  getMTNPaymentStatsActions,
  getOtherPaymentStatsActions,
  getSamphonePaymentStatsActions,
  getTodayRevenueActions,
} from "../../store/posOverView/actions";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PaymentMode from "./sections/PaymentMode";
import { ReactComponent as IconPark } from "../../assets/icons/icon-park-outline_sales-report.svg";
import { Card, DatePicker, DatePickerProps, Modal, Spin } from "antd";
import dayjs from "dayjs";
import { getAllAgentRevenueAction } from "../../store/kpi/actions";
import NotificationModal from "../../components/Modals/NotificationModal";
import MissingRRACodeNotification from "../../components/notification/MissingRRACodeNotification";
import { getAllUnrecordedRRAcodeAction } from "../../store/notification/actions";
import { ReactComponent as TotalCommission } from "../../assets/icons/totalCommissionIcon.svg";
import { ReactComponent as TotalQuantityIcon } from "../../assets/icons/totalQuantityIcon.svg";
import { getDataActivationStatsAction } from "../../store/wareHouse/actions";
import { Stack, useMediaQuery } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import {
  getNosaleAction,
  getOverViewStatsActions,
  getPendingDevicesAction,
  getSalesStatsByDayActions,
} from "../../store/dashboard/actions";
import { getDefaultCurrencyCode } from "../../utils/CurrencyHelper";

const PageView = (props: any) => {
  const [openShopSelectModal, setOpenShopSelectModal] =
    useState<boolean>(false);
  const {
    auth,
    dashboard,
    appSettings,
    notifications,
    kpi,
    wareHouse,
    company,
  } = useSelector((state: any) => state);

  const [activeShops, setActiveShops] = React.useState<any[]>([]);
  const dispatch = useDispatch();
  const handleClose = () => setOpenShopSelectModal(false);
  const formattedDate = new Date().toISOString().slice(0, 10);
  let today = moment();
  let previousDateToCompare = today.subtract(1, "days");
  let formattedPreviousDate = previousDateToCompare.format("YYYY-MM-DD");
  const [enteredDate, setEnteredDate] = useState<any>(formattedDate);
  const [compareDate, setCompareDate] = useState<any>(formattedPreviousDate);
  const [openNotifyModel, setOpenNotifyModel] = useState(false);

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setEnteredDate(dateString);
  };

  const onChangePreviousDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setCompareDate(dateString);
  };

  const todayRevenue = dashboard?.all?.data[0]?.currentDateRevenue;
  const comparedRevenue = dashboard?.all?.data[0]?.comparedDateRevenue;

  const todayQuantity = dashboard?.all?.data[0]?.currentDateQuantity;
  const comparedQuantity = dashboard?.all?.data[0]?.comparedDateQuantity;

  const todayCustomer = dashboard?.all?.data[0]?.currentDateCustomer;
  const comparedCustomer = dashboard?.all?.data[0]?.comparedDateCustomer;

  const revenuePercentage = calculatePercentageChange(
    todayRevenue,
    comparedRevenue
  );

  const quantityPercentage = calculatePercentageChange(
    todayQuantity,
    comparedQuantity
  );

  const customerPercentage = calculatePercentageChange(
    todayCustomer,
    comparedCustomer
  );

  const otherPaymentMethos = ["Macye_Macye"];
  const samphonePaymentMethos = [
    "Samphone_Bank_Deposit",
    "Samphone_POS",
    "MoMo_Pay",
  ];
  const mtnPaymentMethos = [
    "MTN_POS",
    "MTN_Staff_Device_Requisition",
    "MTN_Bank_Deposit",
  ];

  const shopId: any =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  React.useEffect(() => {
    setActiveShops(getActiveShop(auth?.user?.shop?.assigned));
  }, [auth?.user?.shop?.assigned]);

  React.useEffect(() => {
    if (auth?.token && enteredDate && compareDate) {
      getOverViewStatsActions(
        auth?.token,
        `date1=${enteredDate}&date2=${compareDate}&shops=${shopId}`
      )(dispatch);
      getTodayRevenueActions(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&shops=${shopId}`
      )(dispatch);
      getSalesStatsByDayActions(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&shops=${shopId}`
      )(dispatch);
      getDataActivationStatsAction(
        auth?.token,
        `dataActiveted?date1=${enteredDate}&date2=${compareDate}&shops=${shopId}`
      )(dispatch);
      getOtherPaymentStatsActions(
        { modes: otherPaymentMethos },
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&shops=${shopId}`
      )(dispatch);
      getSamphonePaymentStatsActions(
        { modes: samphonePaymentMethos },
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&shops=${shopId}`
      )(dispatch);
      getMTNPaymentStatsActions(
        { modes: mtnPaymentMethos },
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&shops=${shopId}`
      )(dispatch);
      getAllAgentRevenueAction(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&shops=${shopId}`
      )(dispatch);
      getPendingDevicesAction(
        auth?.token,
        `?date2=${compareDate}&date1=${enteredDate}&shop=${shopId}`
      )(dispatch);
      getNosaleAction(
        auth?.token,
        `?date2=${compareDate}&date1=${enteredDate}&shop=${shopId}`
      )(dispatch);
    }
  }, [
    auth?.token,
    appSettings?.selectedShopNumber,
    dispatch,
    enteredDate,
    compareDate,
    shopId,
  ]);

  React.useEffect(() => {
    auth?.token && getRecentPurchaseOrdersAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  React.useEffect(() => {
    auth?.token &&
      getRecentPosOrdersAction(
        auth?.token,
        `?shop=${shopId}&status=In-Transit`
      )(dispatch);
  }, [
    appSettings?.selectedShopNumber,
    auth?.token,
    auth?.user?.shop?.assigned,
    auth?.userShop?.shopId,
    shopId,
    dispatch,
  ]);

  const disabledDate = (current: any) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    if (auth?.token && shopId) {
      getShopCommissionBalanceAction(auth?.token, shopId)(dispatch);
    }
  }, [shopId, auth?.token, dispatch]);

  React.useEffect(() => {
    ["sales-agent"].includes(auth?.user?.role?.toLowerCase()) &&
      getAllUnrecordedRRAcodeAction(auth?.token, `?shopId=${shopId}`)(dispatch);
  }, [auth?.token, auth?.user?.role, shopId, dispatch]);

  React.useEffect(() => {
    notifications?.missingRRACode?.total > 0 && setOpenNotifyModel(true);
  }, [notifications?.missingRRACode?.total]);

  const cardData = [
    {
      title: "Total Sales",
      amount: `${getDefaultCurrencyCode(company)} ${
        dashboard?.overviewStats?.data?.revenue?.currentDate?.toLocaleString() ??
        0
      }`,
      yesterdayAmount: `${getDefaultCurrencyCode(company)} ${
        dashboard?.overviewStats?.data?.revenue?.comparedDate?.toLocaleString() ??
        0
      }`,
      icon: <IconPark />,
      percent: `${revenuePercentage?.percentage}%`,
      type: `${
        customerPercentage?.type === "increase" ? (
          <ArrowDropUpIcon />
        ) : customerPercentage?.type === "decrease" ? (
          <ArrowDropDownIcon sx={{ fontSize: "10px" }} />
        ) : (
          ""
        )
      }`,
    },
    {
      title: "Total Commission",
      amount: `${getDefaultCurrencyCode(company)} ${
        dashboard?.overviewStats?.data?.commission.currentDate ?? 0
      }`,
      yesterdayAmount: `${getDefaultCurrencyCode(company)} ${
        dashboard?.overviewStats?.data?.commission.comparedDate ?? 0
      } `,
      icon: <TotalCommission />,
      percent: `${quantityPercentage?.percentage}%`,
      type: `${
        customerPercentage?.type === "increase" ? (
          <ArrowDropUpIcon />
        ) : customerPercentage?.type === "decrease" ? (
          <ArrowDropDownIcon sx={{ fontSize: "30px" }} />
        ) : (
          ""
        )
      }`,
    },
    {
      title: "Total Quantity",
      amount: `${
        dashboard?.overviewStats?.data?.quantity.currentDate ?? 0
      } Devices`,
      yesterdayAmount: `${
        dashboard?.overviewStats?.data?.quantity.comparedDate ?? 0
      } Devices`,
      icon: <TotalQuantityIcon />,
      percent: `${quantityPercentage?.percentage || 0}%`,
      type: `${
        customerPercentage?.type === "increase" ? (
          <ArrowDropUpIcon />
        ) : customerPercentage?.type === "decrease" ? (
          <ArrowDropDownIcon sx={{ fontSize: "30px" }} />
        ) : (
          ""
        )
      }`,
    },
    {
      title: "Total Customers",
      amount: `${
        dashboard?.overviewStats?.data?.customer.currentDate ?? 0
      } People`,
      yesterdayAmount: `${
        dashboard?.overviewStats?.data?.customer.comparedDate ?? 0
      } People`,
      icon: <TotalQuantityIcon />,
      percent: `${customerPercentage?.percentage}%`,
      type: `${
        customerPercentage?.type === "increase"
          ? "up"
          : customerPercentage?.type === "decrease"
          ? "down"
          : ""
      }`,
    },
  ];

  const currentDate =
    wareHouse?.dataActivation?.data?.quantity?.currentDate?.totalQuantity;
  const comparedDate =
    wareHouse?.dataActivation?.data?.quantity?.comparedDate?.totalQuantity;
  const extraSmallScreen = useMediaQuery("(max-width:330px)");
  return (
    <Stack>
      <div className="gap-x-2 text-black min-h-screen w-full z-0 ">
        <div className="flex flex-wrap my-5 items-center justify-between px-4 h-full md:hd-[56px] w-[100%]  rounded-md">
          <div className="text-[#030229] font-poppins text-base font-medium leading-[27px] text-left">
            Statistics
          </div>
          <div className="flex flex-row justify-between items-center text-large font-poppins leading-6 text-[#030229]">
            <div className={`${extraSmallScreen ? "block" : "flex"} space-x-4`}>
              <DatePicker
                onChange={onChangeDate}
                defaultValue={dayjs()}
                style={{ width: 120 }}
                disabledDate={disabledDate}
              />
              <div className={`flex space-x-2 mt-3 sm:mt-0`}>
                <p className="text-[12px] md:text-base">Compared To</p>
                <DatePicker
                  onChange={onChangePreviousDate}
                  defaultValue={dayjs().subtract(1, "day")}
                  style={{ width: 120 }}
                  disabledDate={disabledDate}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-center   gap-4  px-2 h-full  py-1 w-[100%]  rounded-md">
          {cardData.map((card, index) => (
            <Card key={index} className="flex rounded-md h-full relatxive">
              <div className="flex flex-row gap-7 xl:gap-16 w-full">
                <div>{card.icon}</div>
                <div>
                  <div className="text-[16px]  font-normal text-[#030229]">
                    {card.title}
                  </div>
                  {kpi?.isFetching ? (
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      }
                    />
                  ) : (
                    <>
                      <div className=" font-semibold text-[18px] text-[#030229] opacity-80">
                        {card.amount}
                      </div>
                      <div className=" font-semibold text-[18px] text-[#030229] opacity-50">
                        {card.yesterdayAmount}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="text-green-500 bg-[#0FA9580D]  h-4 w-6 rounded-md absolute top-1 xl:top-2 right-6">
                <p>{card?.percent}</p>
              </div>
            </Card>
          ))}
        </div>

        <div className="pt-10 px-4  gap-4 ">
          <TotalRevenueAndDataActivation
            currentDateProp={currentDate}
            comparedDateProp={comparedDate}
          />
        </div>
        <div>
          <PaymentMode />
        </div>
        <Modal
          open={auth?.user?.isPswdDefault}
          footer={null}
          title="Update PIN Code"
        >
          <UpdatePasswordForm _id={auth?.user?._id} />
        </Modal>
      </div>
      <NotificationModal
        openModal={false}
        title={`Unfinished Activities (${notifications?.missingRRACode?.total})`}
        content={<MissingRRACodeNotification />}
      />
    </Stack>
  );
};

export default PageView;
