import { notification } from "antd";
import { applicantsActions } from ".";
import {
  createServiceCustomer,
  getAllApplicantsService,
  getApplicantsDetails,
  updateServiceCustomer,
} from "./services";

export const createCustomerAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(applicantsActions.setIsFetching(true));

      const res = await createServiceCustomer(data, token);

      if (res?.status === 201) {
        dispatch(applicantsActions.setNew(res));
        dispatch(applicantsActions.setIsFetching(false));
        notification.success({
          message: "Added Successfully!",
        });
        return { type: true, data: res?.data };
      } else if (res?.response?.status === 409) {
        dispatch(applicantsActions.setIsFetching(false));
        notification.warning({
          message: res?.response?.data?.message,
        });
        return { type: false, data: {} };
      }
      dispatch(applicantsActions.setIsFetching(false));
      return { type: false, data: {} };
    } catch (err) {
      console.log(err);
    }
  };
};
export const updateCustomerAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(applicantsActions.setIsFetching(true));
      const res = await updateServiceCustomer(itemId, data, token);
      if (res?.status === 200) {
        dispatch(applicantsActions.setUpdated(res));
        dispatch(applicantsActions.setNew(res));
        dispatch(applicantsActions.setIsFetching(false));
        return { type: true, data: res?.data };
      } else {
        dispatch(applicantsActions.setIsFetching(false));
        return { type: true, data: {} };
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllApplicantsAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(applicantsActions.setIsFetching(true));
      const res = await getAllApplicantsService(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(applicantsActions.setAllApplicants(res));
        dispatch(applicantsActions.setIsFetching(false));
      }
      dispatch(applicantsActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getApplicantsDetailsAction = (token: string, cartId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(applicantsActions.setIsFetching(true));
      const res = await getApplicantsDetails(token, cartId);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(applicantsActions.setApplicantsDetails(res));
        dispatch(applicantsActions.setIsFetching(false));
      }
      dispatch(applicantsActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
