import React from "react";
import { useSelector } from "react-redux";
import PersonnelDetailsForm from "../components/forms/PersonalDetailsForm";
import ConsentAgreementPage from "../components/forms/consentAgreement";

import MatchingPLan from "../../device_financing/Subscription/components/steps/MatchingPLan";
import DeviceScanning from "../../device_financing/Subscription/components/steps/DeviceScanning";
import AddressDetailsForm from "../components/forms/AddressDetailsForm";
import WitnessForm from "../components/forms/WitnessForm";
import BusinnessPlans from "./BussinessPlans";
import UploadDocs from "../components/UploadDocs";
import ApplicantsDetails from "../components/ApplicantsDetails";
import FinancialDetails from "../components/forms/FinancialDetails";
const RegisterCustomer = (props: any) => {
  const { wareHouse } = useSelector((state: any) => state);
  const currentStep = wareHouse?.createdCart?.data?.subscriptionStep?.index;
  const steps = [
    {
      title: "Personnal Details",
      content: (
        <PersonnelDetailsForm
          removeCustomer={props?.onCancel}
          setIsModalOpen={props?.setNewCustomer}
        />
      ),
    },
    {
      title: "Personnal details",
      content: <AddressDetailsForm />,
    },
    {
      title: "Financial details",
      content: <FinancialDetails />,
    },
    {
      title: "Personal details",
      content: <WitnessForm />,
    },
    {
      title: "consent details",
      content: <ConsentAgreementPage removeCustomer={props?.onCancel} />,
    },
    {
      title: "Plans",
      content: <BusinnessPlans />,
    },
    {
      title: "Documents",
      content: <UploadDocs />,
    },
    {
      title: "Applocants Details",
      content: <ApplicantsDetails />,
    },
    // {
    //   title: "Scanning Devices",
    //   content: <DeviceScanning />,
    // },
  ];

  return <div>{steps[currentStep]?.content}</div>;
};

export default RegisterCustomer;
