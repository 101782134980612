import { Form, Input, Select } from "antd";
import React, { useState } from "react";

const FinancialDetails = () => {
  const [otherTypeOfIncome, setOtherTypeOfIncome] = useState(false);
  const { TextArea } = Input;
  return (
    <>
      <div>
        <h1 className="text-[#030229] text-lg xpl-4 pb-4 font-medium text-[18px]">
          Financial Details
        </h1>
      </div>
      <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 xpx-4">
        <Form.Item
          name="mainIncomeSource"
          label={
            <span className="text-[#0F0F47] text-sm">
              Main Source of Income
            </span>
          }
          rules={[
            {
              required: true,
              message: "Source  of Income is required!",
              whitespace: true,
            },
          ]}
        >
          <Select
            className="border-none h-10"
            onChange={(values) =>
              values === "Other" && setOtherTypeOfIncome(true)
            }
          >
            {["Employed", "Own Business"].map((el: any) => (
              <Select.Option key={el} className="capitalize">
                {el}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="occupationOrBusinessType"
          label={<span className="text-[#0F0F47] text-sm">Occupation</span>}
          rules={[
            {
              required: true,
              message: "Occupation is required!",
              whitespace: true,
            },
          ]}
        >
          <Select
            className="border-none h-10"
            onChange={(values) =>
              values === "Other" && setOtherTypeOfIncome(true)
            }
          >
            {[
              "Agriculture and Farming",
              "Retail and Commerce",
              "Service",
              "Manufacturing and Production",
              "Transport and Logistics",
              "Technology and IT",
              "Construction and Real Estate",
              "Health and Wellness",
              "Education and Training",
              "Art and Culture",
              "Financial Services",
              "Others",
            ].map((el: any) => (
              <Select.Option key={el} className="capitalize">
                {el}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          style={{ border: "none" }}
          name="company"
          label={
            <span className="text-[#0F0F47] text-[12px]">Company Name</span>
          }
          rules={[
            {
              required: true,
              message: "Name of the company is required!",
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder="Enter Company name"
            className="pl-4 h-[50px] md:h-[40px] w-[100%] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] border"
          />
        </Form.Item>
        <Form.Item
          style={{ border: "none" }}
          name="monthlyIncome"
          label={<span className="text-[#0F0F47] text-[12px]">Income</span>}
          rules={[
            {
              required: true,
              message: "Income is required!",
              whitespace: true,
            },
          ]}
        >
          <Select
            className="border-none h-10"
            onChange={(values) =>
              values === "Other" && setOtherTypeOfIncome(true)
            }
          >
            {[
              "<50k",
              "50k-100k",
              "100k-150k",
              "150k-200k",
              "200k-250k",
              "250k-300k",
              "300k-350k",
              "350k-400k",
              "400k-600k",
              "> 600k",
            ].map((el: any) => (
              <Select.Option key={el} className="capitalize">
                {el}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="incomeFrequency"
          label={
            <span className="text-[#0F0F47] text-sm">Income Frequency</span>
          }
          rules={[
            {
              required: true,
              message: "Income Frequency is required!",
              whitespace: true,
            },
          ]}
        >
          <Select
            className="border-none h-10"
            onChange={(values) =>
              values === "Other" && setOtherTypeOfIncome(true)
            }
          >
            {["Daily", "Weekly", "Monthly", "Seasonally"].map((el: any) => (
              <Select.Option key={el} className="capitalize">
                {el}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          style={{ border: "none" }}
          name="ownsSmartphone"
          label={
            <span className="text-[#0F0F47] text-[12px]">
              Do you currently own a smartphone?
            </span>
          }
          rules={[
            {
              required: true,
              message: "Field is required!",
              whitespace: true,
            },
          ]}
        >
          <Select className="border-none h-10">
            {["Yes", "No"].map((el: any) => (
              <Select.Option key={el} className="capitalize">
                {el}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          style={{ border: "none" }}
          name="comment"
          label={<span className="text-[#0F0F47] text-[12px]">Comment</span>}
          rules={[
            {
              whitespace: true,
            },
          ]}
        >
          <TextArea
            rows={6}
            placeholder="Comment/ TIN if compony is yours/ company location"
          />
        </Form.Item>
      </div>
    </>
  );
};

export default FinancialDetails;
