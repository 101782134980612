import { Carousel, Drawer, Radio, RadioChangeEvent } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import defaultPic from "../../../assets/nophoto.png";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { getAllPlansAction } from "../../device_financing/Subscription/redux/planManagement/actions";
import { updateShopStockCartDetailsAction } from "../../../store/wareHouse/actions";
import { ProgressInfobar } from "../components/ProgresssInfoBar";
import { getSpecificationValue } from "../../../utils/converter";
import { CurstomeCardSkeleton } from "../../../components/skeleton/Card";
import { getDefaultCurrencyCode } from "../../../utils/CurrencyHelper";
import Okmark from "../../../assets/okmark.png";

const BusinnessPlans = () => {
  const { auth, planManagement, wareHouse, company } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const { cartId } = useParams();
  const [value, setValue] = useState(1);
  const [selectePlan, setselectePlan] = useState("monthly");
  const [loadingPlanId, setLoadingPlanId] = useState<string | null>(null);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [loadingStep, setLoadingStep] = useState<any>(null);

  const channelId = auth?.user?.shop?.channel || auth?.userShop?.channelId;
  const businessProduct = wareHouse?.createdCart?.data?.businessProduct?.name;
  const isRequireFinanceReview =
    wareHouse?.createdCart?.data?.businessProduct?.requestFinanceReview;
  const selectedPlanId = wareHouse?.createdCart?.data?.selectedPlan?._id;

  const handlePrevious = async () => {
    setLoadingStep("back");
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      subscriptionStep: {
        name: "ACCEPT_CONSENT",
        index: 4,
      },
    })(dispatch);
  };

  const handleNextStep = async () => {
    setLoadingStep("next");
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      subscriptionStep: {
        name: "UPLOAD_DOCS",
        index: 6,
      },
    })(dispatch);
  };

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const onChangePlan = (e: RadioChangeEvent) => {
    setselectePlan(e.target.value);
  };

  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const closeDrawer = () => {
    setVisibleDrawer(false);
  };

  useEffect(() => {
    getAllPlansAction(
      auth?.token,
      `?businessProductType=${businessProduct}&channel=${channelId}`
    )(dispatch);
  }, [auth?.token, dispatch, businessProduct, channelId]);

  const handleChoosePlan = async (value: any) => {
    setLoadingPlanId(value?._id);
    const res = await updateShopStockCartDetailsAction(auth?.token, cartId, {
      selectedPlan: value?._id,
      subscriptionPlan: value?.duration,
      instalmentPlan: selectePlan,
      other: {
        bundle: value?.bundle?.at(0),
        call: value?.call?.at(0),
        sms: value?.sms?.at(0),
        period: "12",
      },
    })(dispatch);
    if (res) {
      setLoadingPlanId(null);
    }
  };

  return (
    <div>
      <ProgressInfobar name={"Matching Plans"} percentage={90} />
      <Drawer
        placement="left"
        width={250}
        closable={false}
        onClose={closeDrawer}
        open={visibleDrawer}
      >
        <div className=" py-4 px-2">
          <h2 className="text-lg font-semibold mb-4 text-[#030229]">Filter:</h2>
          <div className="mb-6">
            <h3 className="font-medium mb-2 text-lg  text-[#030229]">
              By Brands
            </h3>

            <Radio.Group
              onChange={onChange}
              value={value}
              className="flex flex-col ml-2"
            >
              <Radio value={1}>All Brands</Radio>
              <Radio value={2}>iPhone</Radio>
              <Radio value={3}>Infinix</Radio>
              <Radio value={4}>Samsung</Radio>
            </Radio.Group>
          </div>
          <div>
            <h3 className="font-medium text-lg  mb-2 text-[#030229]">
              By Installment Plan
            </h3>
            <Radio.Group
              onChange={onChangePlan}
              value={selectePlan}
              className="flex flex-col ml-2"
              defaultValue={"month"}
            >
              <Radio value={"daily"}>Daily</Radio>
              <Radio value={"weekly"}>Weekly</Radio>
              <Radio value={"monthly"}>Monthly</Radio>
            </Radio.Group>
          </div>
          <div className="mt-10 absolute bottom-5">
            <LoadingButton
              onClick={closeDrawer}
              sx={{
                height: 40,
                background: "#605BFF",
                color: "white",
              }}
              className="mt-10 w-48 transition"
            >
              Apply
            </LoadingButton>
          </div>
        </div>
      </Drawer>
      <div className="w-full px-6 pt-4 ">
        {planManagement?.all?.data?.length > 0 ? (
          <h1 className="2xl:mb-6 text-[#030229] text-lg mb-3 font-medium">
            Recommended devices
          </h1>
        ) : (
          <h1 className="text-sm font-medium mb-2 2xl:mb-6">
            No Recommended devices
          </h1>
        )}

        <div className="md:flex gap-6 py-4">
          <div className="flex-1 h-[calc(100vh-280px)] overflow-y-auto">
            {planManagement?.isFetching ? (
              <div className="grid md:grid-cols-2 2xl:grid-cols-3">
                {[1, 2, 3, 1, 2, 3]?.map((el: any) => (
                  <CurstomeCardSkeleton />
                ))}
              </div>
            ) : (
              <div className="grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
                {planManagement?.all?.data
                  ?.filter((el: any) => el?.duration)
                  ?.map((plan: any) => {
                    console.log(planManagement, "planplan");
                    const capacity = getSpecificationValue(
                      plan?.pricelistItem?.specification,
                      ["capacity (rom)", "rom", "capacity"]
                    );
                    const memory = getSpecificationValue(
                      plan?.pricelistItem?.specification,
                      ["memory", "memory (ram)", "ram", "memory(ram)"]
                    );

                    const price =
                      selectePlan === "daily"
                        ? plan?.dailyInstalment
                        : selectePlan === "weekly"
                        ? plan?.weeklyInstalment
                        : plan?.monthlyInstalment;

                    const isSelected = plan?._id === selectedPlanId;

                    return (
                      <div className="border-2 rounded-xl shadow p-6 text-center bg-white ">
                        <div className="flex items-center gap-x-3">
                          <div style={{ width: "6rem" }}>
                            <Carousel autoplay arrows>
                              {plan?.pricelistItem?.product?.images?.length >
                              0 ? (
                                plan?.pricelistItem?.product?.images?.map(
                                  (el: any) => (
                                    <img
                                      src={el}
                                      alt={`images`}
                                      className="w-24 h-24 object-cover"
                                    />
                                  )
                                )
                              ) : (
                                <img
                                  src={defaultPic}
                                  alt={`images`}
                                  className="w-24 h-24 object-cover"
                                />
                              )}
                            </Carousel>
                          </div>

                          <div className="text-start">
                            <h1 className="text-base sm:text-xl font-semibold mb-2">
                              {plan?.pricelistItem?.product?.model}
                            </h1>
                            <div className="text-left">
                              <p className="text-sm text-gray-500 capitalize">
                                <span className="text-black text-sm sm:text-base">
                                  Brand:{" "}
                                </span>{" "}
                                {plan?.pricelistItem?.product?.brand}
                              </p>
                              <p className="text-sm text-gray-500">
                                <span className="text-black text-sm sm:text-base">
                                  Capacity(ROM):{" "}
                                </span>{" "}
                                {capacity}
                              </p>
                              <p className="text-sm text-gray-500 mb-4">
                                <span className="text-black text-sm sm:text-base">
                                  Memory(RAM):{" "}
                                </span>{" "}
                                {memory}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="text-left mb-4">
                          <h4 className="text-sm font-medium mb-2">
                            Includes:
                          </h4>
                          <ul className="space-y-2">
                            <li className="flex items-center">
                              <div className="mr-1 bg-[#efeeff] rounded-full p-1.5">
                                <svg
                                  className="flex-shrink-0 w-3 h-3 text-blue-500 "
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 16 12"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M1 5.917 5.724 10.5 15 1.5"
                                  />
                                </svg>
                              </div>
                              <p
                                className={`${
                                  plan?.bundle?.at(0) === undefined &&
                                  "line-through"
                                } text-sm text-gray-700`}
                              >
                                Bundles:{" "}
                                {`${plan?.bundle?.at(0) || "0"}  / Per Month`}
                              </p>
                            </li>
                            <li className="flex items-center">
                              <div className="mr-1 bg-[#efeeff] rounded-full p-1.5">
                                <svg
                                  className="flex-shrink-0 w-3 h-3 text-blue-500 "
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 16 12"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M1 5.917 5.724 10.5 15 1.5"
                                  />
                                </svg>
                              </div>
                              <p
                                className={`${
                                  plan?.bundle?.at(0) === undefined &&
                                  "line-through"
                                } text-sm text-gray-700`}
                              >
                                SMS: {`${plan?.sms?.at(0) || "0"} / Per Month`}
                              </p>
                            </li>
                            <li className="flex items-center">
                              <div className="mr-1 bg-[#efeeff] rounded-full p-1.5">
                                <svg
                                  className="flex-shrink-0 w-3 h-3 text-blue-500 "
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 16 12"
                                >
                                  <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M1 5.917 5.724 10.5 15 1.5"
                                  />
                                </svg>
                              </div>
                              <p
                                className={`${
                                  plan?.bundle?.at(0) === undefined &&
                                  "line-through"
                                } text-sm text-gray-700`}
                              >
                                Airtimes:{" "}
                                {`${plan?.call?.at(0) || "0"} / Per Month`}
                              </p>
                            </li>
                          </ul>
                        </div>

                        <div className="grid grid-cols-2 gap-2 text-start xitems-baseline mb-4">
                          <div className="pl-2">
                            <p className="text-sm sm:text-xl font-semibold">{`${plan?.initialPayment?.toLocaleString()} ${getDefaultCurrencyCode(
                              company
                            )}`}</p>
                            <span className="capitalize text-sm sm:text-base">
                              Upfront
                            </span>
                          </div>
                          <div className="border-r pr-1">
                            <p className="text-sm sm:text-xl font-semibold">{`${price?.toLocaleString()} ${getDefaultCurrencyCode(
                              company
                            )}`}</p>
                            <span className="capitalize text-sm sm:text-base">{`/${selectePlan}`}</span>
                          </div>
                        </div>
                        <p className="text-sm text-gray-500 mb-4 text-start">
                          <span className="text-sm sm:text-base font-semibold pr-2">{`${
                            plan?.duration ?? "N/A"
                          } Months`}</span>
                          Duration
                        </p>

                        <LoadingButton
                          loading={
                            wareHouse?.isUpdateFetching &&
                            loadingPlanId === plan?._id
                          }
                          disabled={wareHouse?.isUpdateFetching}
                          sx={{
                            height: 40,
                            background: isSelected ? "" : "#f0efff",
                            color: "blue",
                          }}
                          className="mt-4 text-sm sm:text-base w-48 transition"
                          onClick={() => {
                            handleChoosePlan(plan);
                          }}
                        >
                          {isSelected ? (
                            <img src={Okmark} alt="" className="h-10 w-10" />
                          ) : (
                            " Choose plan"
                          )}
                        </LoadingButton>
                      </div>
                    );
                  })}
              </div>
            )}

            <div className="flex justify-between space-x-4 mt-5">
              <LoadingButton
                variant="outlined"
                style={{
                  width: "150px",
                  height: 40,
                }}
                onClick={handlePrevious}
                loading={loadingStep === "back" && wareHouse?.isUpdateFetching}
                disabled={wareHouse?.isUpdateFetching}
              >
                {`Previous`}
              </LoadingButton>
              <LoadingButton
                type="submit"
                variant="contained"
                onClick={handleNextStep}
                style={{
                  padding: "7px",
                  height: 40,
                }}
                className="w-[150px] h-[45px] text-lg uppercase "
                loading={loadingStep === "next" && wareHouse?.isUpdateFetching}
                disabled={
                  !wareHouse?.createdCart?.data?.selectedPlan ||
                  wareHouse?.isUpdateFetching
                }
              >
                {"Next"}
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinnessPlans;
