import React, { useEffect, useState } from "react";
// import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import DetailsTabs from "../../../components/cards/DetailsPage/DetailsTabs";
import { useNavigate } from "react-router-dom";
import SidePanelDetails from "./SidePanelCustomerDetails";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import CustomerDevices from "./Devices/CustomerDevices";
import { Avatar, Card, Spin, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomerInvoice from "./invoice/CustomerInvoice";
import Index from "./Billings/CustomerBillings";
import CustomerCard from "./customerCard";
import ContactInfoCard from "./ContactInfoCard";
import CreditReportCard from "./CreditReportCar";
import OverViewCard from "../../../components/cards/OverviewCard";
import { ReactComponent as BoxIcons } from "../../../assets/icons/dashboard/box-tick.svg";
import {
  getBellingInvoiceAction,
  getCustomerInvoiceAction,
  getCustomerMetricsAction,
  getCustomerNoSaleAction,
  getCustomerNotesAction,
  getCustomerPurchaseAction,
} from "../../../store/customer/actions";
import { getAllAccountAction } from "../../../store/account/actions";
import { getOneCompanyAction } from "../../../store/company/actions";
import CustomerProductTable from "../../../components/tables/CustomerProductsTable";
import CustomerRepairTable from "../../../components/tables/CustomerRepairTable";
import CustomerSubscriptionTable from "../../../components/tables/CustomerSubscriptionTable";
import NotesCard from "./NotesCard";
import { LoadingOutlined } from "@ant-design/icons";
import File from "./files/File";
import CustomerNoSaleTable from "../../../components/tables/CustomerNoSaleTable";
import { COMPANY_PROFILE_ID } from "../../../utils/constants";
import { getDefaultCurrencyCode } from "../../../utils/CurrencyHelper";
import SubscriptionPage from "./accounts/SubscriptionPage";
import CashsalesPage from "./cashsales/CashsalesPage";
import { getAllcashsalesAction } from "../../../store/pos/actions";
const DetailsPage = (props: any) => {
  const { auth, customer, account, layout,pos } = useSelector(
    (state: any) => state
  );
  
  const [loading, setLoading] = useState<boolean>(true);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goBack = () => {
    navigate(-1);
  };
  const styles = {
    customShadow: {
      boxShadow: "0px 10px 20px 0px rgba(194, 194, 194, 0.16)",
    },
    border: {
      border: "0px 1px 0px 0px solid rgba(3, 2, 41, 0.04)",
    },
  };
  const accountId = account?.all?.data[0]?._id;
  useEffect(() => {
    auth?.token &&
      getCustomerInvoiceAction(
        auth?.token,
        `${customer?.selected?._id}?limit=${limit}&page=${page}&status=${status}`
      )(dispatch);
  }, [customer?.selected?._id, dispatch, page, limit, status]);
  useEffect(() => {
    getBellingInvoiceAction(
      auth?.token,
      `?account=${accountId}&limit=${limit}&page=${page - 1}`
    )(dispatch);
  }, [accountId, dispatch, page, limit]);
  React.useEffect(() => {
    auth?.token &&
      getCustomerPurchaseAction(
        auth?.token,
        `?customer=${customer?.selected?._id}`
      )(dispatch);
  }, [auth?.token, customer?.selected?._id, dispatch]);
  useEffect(() => {
    auth?.token &&
      customer?.selected?._id &&
      getCustomerNotesAction(
        auth?.token,
        `?customerId=${customer?.selected?._id}`
      )(dispatch);
  }, [customer?.selected?._id, dispatch]);
  useEffect(() => {
    auth?.token &&
      customer?.selected?._id &&
      getCustomerNoSaleAction(
        auth?.token,
        `?customerId=${customer?.selected?._id}`
      )(dispatch);
  }, [customer?.selected?._id, dispatch]);
  useEffect(() => {
    auth?.token &&
      getAllAccountAction(
        auth?.token,
        `?customer=${customer?.selected?._id}`
      )(dispatch);
  }, [auth?.token, customer?.selected?._id, dispatch]);

  const companyId = COMPANY_PROFILE_ID;

  useEffect(() => {
    if (auth?.token && companyId) {
      getOneCompanyAction(companyId as string, auth.token)(dispatch);
    }
  }, [auth.token, dispatch, companyId]);
  useEffect(() => {
    auth?.token &&
      customer?.selected?._id &&
      getCustomerMetricsAction(customer?.selected?._id, auth.token)(dispatch);
  }, [auth.token, dispatch, customer?.selected?._id]);

  useEffect(() => {
    if (auth?.token) {
      getAllcashsalesAction(auth?.token,`?customer=${customer?.selected?._id}&isSubscription=${"pos"}`)(dispatch);
    }
  }, [auth.token, dispatch,customer?.selected?._id]);

  const tabsComponent = [
    {
      title: (
        <h1 className="text-base">{`cash sales (${
          pos?.cashsales?.data.length ?? "0"
        })`}</h1>
      ),
      components: <CashsalesPage />,
    },
    {
      title: (
        <h1 className="text-base">{`Subscription (${
          account?.all?.data?.length ?? "0"
        })`}</h1>
      ),
      components: <SubscriptionPage />,
    },
    {
      title: (
        <h1 className="text-base">
          Devices ({customer?.purchased?.length ?? "0"})
        </h1>
      ),
      components: <CustomerDevices />,
    },
    {
      title: <h1 className="text-base">Files</h1>,
      components: <File />,
    },
    {
      title: (
        <h1 className="text-base">
          Notes ({customer?.customerNotes?.total ?? 0})
        </h1>
      ),
      components: (
        <div>
          <NotesCard notes={customer?.customerNotes?.data} />
        </div>
      ),
    },
    {
      title: (
        <h1 className="text-base">
          Repairs ({customer?.customerRepairs?.length || 0})
        </h1>
      ),
      components: <CustomerRepairTable />,
    },
    // {
    //   title: <h1 className="text-base">Subscriptions</h1>,
    //   components: <CustomerSubscriptionTable />,
    // },
    {
      title: (
        <h1 className="text-base">
          No Sales ({customer?.customerNoSale?.total ?? 0})
        </h1>
      ),
      components: (
        <div>
          <CustomerNoSaleTable data={customer?.customerNoSale?.data} />
        </div>
      ),
    },
  ];
  const items = tabsComponent.map((el: any, i) => {
    const id = String(i + 1);
    return {
      label: el?.title,
      key: id,
      children: el?.components,
    };
  });
  return (
    <div className="text-black p-1 mt-3 space-y-2">
      <DetailsHeaderActionBar
        // handleClickDelete={props?.handleClickDelete}
        // handleClickEdit={props?.handleClickEdit}
        goBack={goBack}
        pageName={props?.pageName}
        title={props?.title}
      />
      <div className="lg:flex gap-5 mt-5">
        <div className="2xl:flex gap-5 mt-5">
          {customer?.isFetching || account?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 500, margin: "3px" }}>
              <Card.Meta
                avatar={
                  <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=1" />
                }
              />
            </Card>
          ) : (
            <CustomerCard
              data={customer?.selected}
              onClick={props?.handleClickDelete}
              onEdit={props?.handleClickEdit}
            />
          )}
          <div
            style={styles.customShadow}
            className="bg-white p-5 px-7 lg:w-[480px] w-full rounded-md mt-5 2xl:mt-0"
          >
            <div className="flex justify-between">
              <h1 className="text-[#030229] text-base font-medium">
                Other Contact (+2)
              </h1>
              <button className="text-[#605BFF] text-base">See All</button>
            </div>
            {customer?.isFetching || account?.isFetching ? (
              <Card loading={loading} style={{ minWidth: 300, margin: "3px" }}>
                <Card.Meta
                  avatar={
                    <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=1" />
                  }
                />
              </Card>
            ) : (
              <div className="mt-3">
                <ContactInfoCard data={customer?.selected} />
              </div>
            )}
          </div>
        </div>
        {/* <div className="mt-5 w-full">
          <CreditReportCard />
        </div> */}
        <div
          className={`grid ${
            layout?.isSideNavOpen
              ? "sm:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2"
              : "sm:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2"
          }  mt-5 xls:mt-0 gap-5`}
        >
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 300 }}>
              <Card.Meta />
            </Card>
          ) : (
            <OverViewCard
              text="Total Devices"
              total={
                customer?.customerMetrics?.totalDevices?.toLocaleString() ?? 0
              }
              icon={<BoxIcons />}
            />
          )}
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 300 }}>
              <Card.Meta />
            </Card>
          ) : (
            <OverViewCard
              text="Total Invoice amount"
              total={
                customer?.customerMetrics?.totalAmountPaid?.toLocaleString() ??
                0
              }
              icon={<BoxIcons />}
            />
          )}
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 300 }}>
              <Card.Meta />
            </Card>
          ) : (
            <OverViewCard
              text="Total Bill Amount"
              total={
                customer?.customerMetrics?.totalPendingLateAmount?.toLocaleString() ??
                0
              }
              icon={<BoxIcons />}
            />
          )}
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 300 }}>
              <Card.Meta />
            </Card>
          ) : (
            <OverViewCard
              text="No Sales Amount"
              total={
                customer?.customerMetrics?.totalNoSaleAmount?.toLocaleString() ??
                0
              }
              icon={<BoxIcons />}
            />
          )}
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 300 }}>
              <Card.Meta />
            </Card>
          ) : (
            <OverViewCard
              text="Next Payment Due"
              total={
                customer?.customerMetrics?.nextBillingDate?.slice(0, 10) ??
                "N/A"
              }
              icon={<BoxIcons />}
            />
          )}
          {customer?.isFetching ? (
            <Card loading={loading} style={{ minWidth: 300 }}>
              <Card.Meta />
            </Card>
          ) : (
            <OverViewCard
              text="Upcoming Payment"
              total={
                customer?.customerMetrics?.nextBillingAmount?.toLocaleString() ??
                0
              }
              icon={<BoxIcons />}
            />
          )}
        </div>
      </div>
      <div className="mt-5 py-5 "></div>
      {/* <div className="flex flex-row-reverse gap-2 pt-12"> */}
      {/* <SidePanelDetails
          handleClickDelete={props?.handleClickDelete}
          handleClickEdit={props?.handleClickEdit}
          simpleDetails={props?.simpleDetails}
        /> */}
      {/* Tabs */}
      <Tabs items={items} />
      {/* <DetailsTabs
          tabs={props?.tabs}
          activeTab={activeTab}
          handleChange={handleChange}
        /> */}
      {/* </div> */}
    </div>
  );
};

export default DetailsPage;
