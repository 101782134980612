import { useEffect, useState } from "react";
import {
  getAllRepairsAction,
  getAllRepairsOverviewAction,
  getOneRepairAction,
} from "../../../store/repair/actions";
import { useDispatch, useSelector } from "react-redux";
import RepairCard from "../../../components/cards/pos/RepairCard";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import { DatePicker, Select, Table, Tag } from "antd";
import { GrFormView } from "react-icons/gr";
import OverViewCard from "../../../components/cards/inventory/OverViewCard";
import { ReactComponent as BoxIcons } from "../../../assets/icons/dashboard/box-tick.svg";
import { GoListUnordered } from "react-icons/go";
import { TbGridDots } from "react-icons/tb";
import CustomButton from "../../../components/buttons/CustomButton";
import { IoFilterOutline } from "react-icons/io5";
import CustomDrawer from "../../../components/Modals/CustomDrawer";
import RepairDetails from "./RepairDetails";
import { getActiveShop } from "../../../utils/converter";
import { searchTextValue, searchValue } from "../../../utils/setColor";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const RepairDevices = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const { auth, repair, appSettings } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>(null);
  const showDrawer = (d: any) => {
    setOpen(true);
    setData(d);
  };
  const onClose = () => {
    setOpen(false);
  };
  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;
  // const shopId =
  //   getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
  //     ?.shop?._id ?? shop?.selected?._id;
  useEffect(() => {
    auth?.token &&
      getAllRepairsAction(auth?.token, `?shop=${shopId}`)(dispatch);
    // getAllRepairsAction(auth?.token, `?shop=${auth?.user?._id}`)(dispatch);
  }, [auth?.token, auth.user._id, dispatch, limit, page, shopId]);
  useEffect(() => {
    auth?.token &&
      getAllRepairsOverviewAction(auth?.token, `?shop=${shopId}`)(dispatch);
  }, [auth?.token, shopId, dispatch]);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "shop",
      dataIndex: "shop",
    },
    {
      title: "IMEI",
      dataIndex: "serialNumber",
    },
    {
      title: "Model",
      dataIndex: "model",
      width: "40",
    },
    {
      title: "Descriptions",
      dataIndex: "data",
      width: 400,
      render: (text: any, record: any) => {
        return (
          <>
            {record?.data?.cartItemId?.warehouseProduct?.warehouseItem?.purchaseItem?.quotationItem?.specification?.map(
              (d: any, index: number) => (
                <span key={index} className="text-xs">
                  {d[0]}: {d[1]}
                </span>
              )
            )}
          </>
        );
      },
    },
    {
      title: "Customer",
      dataIndex: "customer",
    },
    {
      title: "Repaired At",
      dataIndex: "repairedAt",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: any) => {
        return (
          <Tag
            color={searchValue(text)}
            className="p-1 px-3 w-[6.5rem] capitalize"
            style={{ textAlign: "center", borderRadius: "20px" }}
          >
            <p className={`text-[${searchTextValue(text)}]`}>{text}</p>
          </Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "view",
      render: (text: any, record: any) => {
        return (
          <div
            className="flex gap-2 cursor-pointer"
            onClick={() => showDrawer(record)}
          >
            <GrFormView size={25} />
          </div>
          //   <Dropdown menu={{
          //     items: [
          //       {
          //         key: '1',
          //         label: (
          //           <div className="flex gap-2" onClick={() => showDrawer(record)}>
          //             <GrFormView size={25}/>
          //             <span className="text-sm pt-1">View</span>
          //           </div>
          //         ),
          //       },
          //       {
          //         key: '2',
          //         label: <div className="flex gap-2"><TickIcons/><span className="text-sm">Complete</span></div>,
          //       },
          //       {
          //         key: '3',
          //         label:<div className="flex gap-2"><CloseIcons/><span className="text-sm">Reject</span></div>,
          //       },
          //     ]
          //   }} placement="bottomLeft" arrow>
          //   <BsThreeDots
          //     size={20}
          //     className="cursor-pointer"
          //   />
          // </Dropdown>
        );
      },
    },
  ];

  const repairData = repair?.all?.data?.map((el: any) => {
    return {
      key: el?._id,
      model: el?.cartItemId?.priceProduct?.product?.model,
      serialNumber: el?.cartItemId?.warehouseProduct?.serialNumber,
      status: el?.status,
      shop: el?.shop?.name,
      customer: el?.cartItemId?.cart?.customer?.name,
      repairedAt: el?.repairedAt,
      date: el?.createdAt?.slice(0, 10),
      data: el,
    };
  });


  return (
    <div>
      <CustomDrawer
        component={
          <div>
            <RepairDetails data={data} />
          </div>
        }
        openDrawer={open}
        closeDrawer={onClose}
        title={
          <h1 className="text-center text-[#030229] text-base md:text-lg xl:text-2xl font-medium">
            Repair
          </h1>
        }
        width={700}
      />
      <h1 className="text-[#030229d8] text-lg md:text-xl pb-2">Repair</h1>
      <div>
        <div
          className={`grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 text-white`}
        >
          <OverViewCard
            text="Total Devices"
            total={
              repair?.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                repair?.repairOverView?.data?.allRepairedDevices
              )
            }
            icon={<BoxIcons />}
          />
          <OverViewCard
            text="Total Pending"
            total={
              repair?.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                repair?.repairOverView?.data?.fixedDevices
              )
            }
            icon={<BoxIcons />}
          />
          <OverViewCard
            text="Total Completed"
            total={
              repair?.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                repair?.repairOverView?.data?.pendingDevice
              )
            }
            icon={<BoxIcons />}
          />
          <OverViewCard
            text="Total Rejected"
            total={
              repair?.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                "0"
              )
            }
            icon={<BoxIcons />}
          />
        </div>
        <div className="flex justify-between my-5">
          <div className="flex gap-5 flex-wrap">
            <CustomButton
              title={showFilter ? "" : "filter"}
              textColor="[#030229B2]"
              icon={<IoFilterOutline size={20} color="black" />}
              bgColor={"white"}
              border="[#0302291A]"
              onClick={() => setShowFilter(!showFilter)}
            />
            {showFilter && (
              <Select
                showSearch
                style={{ width: 200 }}
                className="border border-gray-300 rounded-md customSelect"
                placeholder="filter by Model "
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={[
                  {
                    value: "1",
                    label: "Pop 8 (BC6)",
                  },
                  {
                    value: "2",
                    label: "Spark 20",
                  },
                  {
                    value: "3",
                    label: "Pop7",
                  },
                ]}
              />
            )}
          </div>
          <div className="flex gap-5">
            <DatePicker />
            <div className="bg-[#605BFF] flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
              <GoListUnordered size={23} color="#ffff" />
            </div>
          </div>
        </div>
      </div>
      <ScrollableFrame
        loading={repair?.isFetching}
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={repair?.all?.total}
        count={Math.ceil(repair?.all?.total / limit)}
      >
        <Table
          columns={columns}
          dataSource={repairData}
          size="middle"
          pagination={false}
          loading={repair?.isFetching}
        />
      </ScrollableFrame>
    </div>
  );
};

export default RepairDevices;
