import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, notification, Result, Skeleton } from "antd";
import { updateShopStockCartDetailsAction } from "../../../store/wareHouse/actions";
import { createAccountAction } from "../../../store/pos/actions";
import { getDefaultCurrencyCode } from "../../../utils/CurrencyHelper";
import { containsKey, getActiveShop } from "../../../utils/converter";
import { customerValidationAction } from "../../device_financing/Subscription/redux/customer/actions";
import { IoMdClose } from "react-icons/io";

const ApplicantsDetails = () => {
  const { wareHouse, auth, pos, company, subCustomer, shop, appSettings } =
    useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { cartId } = useParams();
  const navigate = useNavigate();
  const [loadingStep, setLoadingStep] = useState<any>(null);

  const isSubmitted = wareHouse?.createdCart?.data?.isSubscriptionSubmitted;
  const planDetails = wareHouse?.createdCart?.data?.selectedPlan;

  const shopName =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?.name || shop?.myShop?.name;

  const userImage =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.photoCustomerHoldingID;

  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;
  const address =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address;

  const handlePrevious = async () => {
    setLoadingStep("back");
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      subscriptionStep: {
        name: "UPLOAD_DOCS",
        index: 6,
      },
    })(dispatch);
  };

  const handleSendRequest = async () => {
    setLoadingStep(null);
    if (wareHouse?.createdCart?.data?.isSubscriptionSubmitted) {
      auth?.user?.profile === "freelancer"
        ? navigate("/cart")
        : navigate("/sale");
    } else {
      const resPre = await updateShopStockCartDetailsAction(
        auth?.token,
        cartId,
        {
          isSubscriptionSubmitted: true,
          subscriptionStatus: "ACCOUNT_REQUEST",
          status: "requested",
          payment: [
            {
              mode: "MoMo_Pay",
              amount:
                planDetails?.planType?.toLowerCase() === "prepaid"
                  ? planDetails?.initialPayment + planDetails?.monthlyInstalment
                  : planDetails?.initialPayment,
            },
          ],
          isNotify: true,
          notification: {
            action: "Device Installment Request",
            role: [
              "sales-manager",
              "sales-supervisor",
              "dev",
              "admin",
              "finance-manager",
            ],
            message: `${auth?.user?.names} from ${shopName} has send a device installment request`,
            title: "Device Installment Request",
          },
        }
      )(dispatch);
      if (resPre) {
        if (wareHouse?.createdCart?.data?.status !== "rejected") {
          await createAccountAction(auth?.token, cartId as string)(dispatch);
          navigate("/home");
        } else {
          navigate("/home");
        }
        notification.success({ message: "Request Sent Successfully" });
      }
    }
  };
  const phone = wareHouse?.createdCart?.data?.customer?.phone;
  const maskedPhone = phone
    ? `${phone.slice(0, 5)}*********${phone.slice(-2)}`
    : "";

  const pNationalId = wareHouse?.createdCart?.data?.customer?.nationalId;
  const maskedNationalId = pNationalId
    ? `${pNationalId.slice(0, 2)}*********${pNationalId.slice(-2)}`
    : "";

  useEffect(() => {
    customerValidationAction(
      wareHouse?.createdCart?.data?.customer?._id,
      {},
      auth?.token
    )(dispatch);
  }, [dispatch, wareHouse?.createdCart?.data?.customer?._id]);

  const customerFailedValue =
    subCustomer?.userValidation?.data?.failedCustomerData;
  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="md:flex gap-5 w-full">
        <div className="border border-[#0302291A] w-full p-4 mt-5 rounded-md">
          <div className="flex justify-between">
            <h1 className="text-[#030229] text-base font-medium py-3 pb-6 text-center">
              Personal Details
            </h1>
            {/* <div
              className="bg-[#E5E5E5] rounded-md h-[26px] w-[56px] flex justify-center items-center gap-2 cursor-pointer"
              //   onClick={showModal}
            >
              <CiEdit size={15} />
              <p className="text-[12px]">Edit</p>
            </div> */}
          </div>
          {customerFailedValue?.length > 0 && (
            <h1 className="text-red-400 text-sm pb-1">
              Some Customer data doesn't match{" "}
            </h1>
          )}
          {subCustomer?.invalidUserData?.data?.status === 400 && (
            <Alert
              message="The phone number is invalid. If you do not include a valid phone number, your request will be rejected."
              type="error"
            />
          )}
          {subCustomer?.isFetching ? (
            <Skeleton />
          ) : (
            <>
              {" "}
              <div className="flex w-full gap-12 mt-6">
                <p className="text-[#03022980]  text-sm">Full Name</p>
                <p
                  className={`text-[#030229] text-sm flex gap-2 ${
                    (containsKey(customerFailedValue, "firstName") ||
                      containsKey(customerFailedValue, "lastName")) &&
                    "line-through"
                  }`}
                >
                  {wareHouse?.createdCart?.data?.customer?.name}{" "}
                  {containsKey(customerFailedValue, "firstName") && (
                    <IoMdClose color="red" className="mt-1" />
                  )}
                </p>
              </div>
              <div className="flex w-full gap-5 mt-6">
                <p className="text-[#03022980]  text-sm">Personal Email</p>
                <p className="text-[#030229] text-sm flex gap-2">
                  {wareHouse?.createdCart?.data?.customer?.email}{" "}
                  {containsKey(customerFailedValue, "email") && (
                    <IoMdClose color="red" className="mt-1" />
                  )}
                </p>
              </div>
              <div className="flex w-full gap-5 mt-3">
                <p className="text-[#03022980]  text-sm">Phone Number</p>
                <p
                  className={`text-[#030229] text-sm flex gap-2 ${
                    subCustomer?.invalidUserData?.data?.status === 400 &&
                    "line-through"
                  }`}
                >
                  {maskedPhone}{" "}
                  {subCustomer?.invalidUserData?.data?.status === 400 && (
                    <IoMdClose color="red" className="mt-1" />
                  )}
                </p>
              </div>
              <div className="flex w-full gap-12 mt-3">
                <p className="text-[#03022980]  text-sm">National Id</p>
                <p
                  className={`text-[#030229] text-sm flex gap-2 ${
                    containsKey(customerFailedValue, "id") && "line-through"
                  }`}
                >
                  {maskedNationalId}{" "}
                  {containsKey(customerFailedValue, "id") && (
                    <IoMdClose color="red" className="mt-1" />
                  )}
                </p>
              </div>
              <div className="flex w-full gap-12 mt-3">
                <p className="text-[#03022980]  text-sm">Gender</p>
                <p
                  className={`text-[#030229] text-sm flex gap-2 capitalize ${
                    containsKey(customerFailedValue, "gender") && "line-through"
                  }`}
                >
                  {wareHouse?.createdCart?.data?.customer?.gender}
                  {containsKey(customerFailedValue, "gender") && (
                    <IoMdClose color="red" className="mt-1" />
                  )}
                </p>
              </div>
              {wareHouse?.createdCart?.data?.status === "rejected" && (
                <p className="pt-2 text-sm sm:text-base">
                  Reject Reason :
                  <span className="text-red-400 text-base pl-1">
                    {wareHouse?.createdCart?.data?.rejectSubReason}
                  </span>{" "}
                </p>
              )}
            </>
          )}
        </div>
        <div className="border border-[#0302291A] p-4 mt-5 rounded-md w-full">
          <div className="flex justify-between">
            <h1 className="text-[#030229] text-base font-medium py-3 pb-6 text-center">
              Address Details
            </h1>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm"> Country</p>
            <p className="text-[#030229] text-sm">{address?.country}</p>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm"> province</p>
            <p className="text-[#030229] text-sm">{address?.province}</p>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm"> District</p>
            <p className="text-[#030229] text-sm">{address?.district}</p>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm">Sector</p>
            <p className="text-[#030229] text-sm">{address?.sector}</p>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm">Cell</p>
            <p className="text-[#030229] text-sm">{address?.cell}</p>
          </div>
          <div className="flex w-full gap-10 mt-3">
            <p className="text-[#03022980]  text-sm">Village</p>
            <p className="text-[#030229] text-sm">{address?.village}</p>
          </div>
        </div>
      </div>
      <div className="border border-[#0302291A] p-4 mt-5 rounded-lg">
        <h1 className="text-[#030229] text-lg font-medium py-3 pb-6 text-center">
          Documents
        </h1>
        <div className=" w-full md:flex gap-5 divide-y-2 md:divide-y-0 md:divide-x-2">
          <div className=" w-full">
            <p className="text-[#030229]  text-sm text-center">
              Passport image
            </p>
            <div className="pb-10 pt-5 w-full flex items-center justify-center rounded-md">
              {userImage ? (
                <Result status="success" />
              ) : (
                <Result status="error" />
              )}
            </div>
          </div>
          <div className="w-full pt-5 md:pt-0  pl-0 md:pl-5">
            <p className="text-[#030229]  text-sm text-center">National Id</p>
            <div className="pb-10  pt-5 w-full flex items-center justify-center rounded-md">
              {nationalId ? (
                <Result status="success" />
              ) : (
                <Result status="error" />
              )}
            </div>
          </div>
        </div>
        {nationalId || userImage ? (
          ""
        ) : (
          <h1 className="text-red-500 text-lg font-medium py-3 pb-6 text-center">
            Please Upload missing documents
          </h1>
        )}
      </div>

      <div className="border border-[#0302291A] p-4 mt-5 rounded-md w-full">
        <div className="flex justify-between">
          <h1 className="text-[#030229] text-base font-medium py-3 pb-6 text-center">
            Plan Details
          </h1>
        </div>
        <div className="flex w-full gap-10 mt-3">
          <p className="text-[#03022980]  text-sm"> Device</p>
          <p className="text-[#030229] text-sm">
            {
              wareHouse?.createdCart?.data?.selectedPlan?.pricelistItem?.product
                ?.model
            }
          </p>
        </div>
        <div className="flex w-full gap-10 mt-5 flex-wrap">
          {wareHouse?.createdCart?.data?.selectedPlan?.pricelistItem?.specification?.map(
            (el: any, index: number) => {
              return (
                <div key={index}>
                  <p className="text-[#03022980] text-sm">{el[0]}</p>
                  <p className="text-[#030229] text-sm">{el[1]}</p>
                </div>
              );
            }
          )}
        </div>
        <div className="flex w-full gap-10 mt-3">
          <p className="text-[#03022980]  text-sm">Installment Amount</p>
          <p className="text-[#030229] text-sm">
            {getDefaultCurrencyCode(company)}{" "}
            {wareHouse?.createdCart?.data?.selectedPlan?.monthlyInstalment?.toLocaleString()}
          </p>
        </div>
        <div className="flex w-full gap-10 mt-3">
          <p className="text-[#03022980]  text-sm">Plan Period</p>
          <p className="text-[#030229] text-sm ">
            {wareHouse?.createdCart?.data?.selectedPlan?.duration} Months
          </p>
        </div>
        <div className="flex w-full gap-10 mt-3">
          <p className="text-[#03022980]  text-sm">Installment Plan</p>
          <p className="text-[#030229] text-sm capitalize">
            {wareHouse?.createdCart?.data?.instalmentPlan}
          </p>
        </div>
        <div className="flex w-full gap-10 mt-3">
          <p className="text-[#03022980]  text-sm">Initial Payment</p>
          <p className="text-[#030229] text-sm">
            {getDefaultCurrencyCode(company)}{" "}
            {wareHouse?.createdCart?.data?.selectedPlan?.initialPayment?.toLocaleString()}
          </p>
        </div>
      </div>
      <div
        className={`flex gap-2 ${
          isSubmitted && wareHouse?.createdCart?.data?.status !== "requested"
            ? "justify-center"
            : "justify-between"
        } mt-8`}
      >
        {["pending", "requested", "rejected"]?.includes(
          wareHouse?.createdCart?.data?.status
        ) ? (
          <LoadingButton
            variant="outlined"
            onClick={handlePrevious}
            className="w-[150px] text-lg uppercase"
            loading={loadingStep === "back" && wareHouse?.isUpdateFetching}
            disabled={wareHouse?.isUpdateFetching}
          >
            Previous
          </LoadingButton>
        ) : (
          wareHouse?.createdCart?.data?.status === "requested" && <div></div>
        )}
        {["pending", "requested", "rejected"]?.includes(
          wareHouse?.createdCart?.data?.status
        ) ? (
          <LoadingButton
            variant="contained"
            sx={{ background: "#605BFF" }}
            loading={pos?.isFetching || wareHouse?.isUpdateFetching}
            onClick={handleSendRequest}
            className="min-w-[150px] text-lg uppercase "
          >
            {wareHouse?.createdCart?.data?.status === "requested"
              ? "Waiting for approval"
              : wareHouse?.createdCart?.data?.status === "rejected"
              ? "Re-Send request"
              : " Send request"}
          </LoadingButton>
        ) : (
          !isSubmitted && <div></div>
        )}
      </div>
    </div>
  );
};
export default ApplicantsDetails;
