import React, { useState } from "react";
import CustomButton from "../../../../components/buttons/CustomButton";
import BillingCard from "./BillingCard";
import InstallmentCard from "./InstallmentCard";
import { ReactComponent as MTNLogo } from "../../../../assets/icons/dashboard/MTN.svg";
import CustomFilter from "../../../../components/filters/CustomFilter";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { getDefaultCurrencyCode } from "../../../../utils/CurrencyHelper";
import BellingDataTable from "../../../../components/tables/BellingDataTable";
import ScrollableFrame from "../../../../components/layout/ScrollableFrame";
import { Select } from "antd";

const CustomerBillings = (props:any) => {
  const { auth, account ,company,customer} = useSelector((state: any) => state);
  const [selectedStatus, setSelectedStatus] = useState("");
  const dataStatus = [
    {
      value: "",
      label: "All Business Products (3)",
    },
    {
      value: "b2cOne",
      label: "B2C One",
    },
    {
      value: "b2cPrepaid",
      label: "B2C Prepaid",
    },
    {
      value: "b2cPostpaid",
      label: "B2C PostPaid",
    },
  ];
  const handleChange = (value: string) => {
    props?.setStatus(value);
  };
  return (
    <div>
      <CustomFilter setSelectedStatus={setSelectedStatus} data={dataStatus} />
      <div className="block xl:flex gap-5 mt-5">
      <div className="md:flex gap-5 mt-5">
        <div className="bg-white rounded-md border border-[#EBEFF2] pt-2 md:pt-auto md:w-[460px] w-full">
          <div className="border-b px-4">
            <div className="flex flex-wrap items-center justify-between font-poppins font-medium text-base leading-6 ml-2 pt-1 pb-2">
              <p> Payment Method </p>
              {/* <CustomButton btnName={'Upgrade Plan'}textColor={"[#605BFF]"} borderColor={'[#605BFF40]'}/> */}
            </div>
          </div>
          <div className="p-4">
            <InstallmentCard
              name={
                <div className="flex gap-5">
                  <MTNLogo />
                  <p className="text-[#101828] font-medium pt-3">{props?.data?.customerInvoice?.data[0]?.payment?.mode
                        ?.split("_")
                        ?.join(" ")}</p>
                </div>
              }
              amount={
                <CustomButton
                  title={"Change"}
                  textColor={"[#605BFF]"}
                  border={"[#605BFF40]"}
                />
              }
            />
            <InstallmentCard secondName={"Payment Type"} amount={"Manual"} />
          </div>
        </div>
        <div className=" bg-white w-full md:w-[633px] rounded-md mt-0 md:mt-0">
          <div className="flex justify-between border-b px-4 py-4">
            <h1 className="text-[#030229] text-base">Current plan Summary</h1>
            <CustomButton
              title={"Upgrade Plan"}
              textColor={"[#605BFF]"}
              border={"[#605BFF40]"}
            />
          </div>
          <div className="grid sm:grid-2 lg:grid-cols-3 gap-5 mt-3 p-4">
            <BillingCard
              name={"Installment"}
              secondName={account?.all?.data[0]?.planType}
            />
            <BillingCard
              name={"Plan duration"}
              secondName={`${account?.all?.data[0]?.duration?? ""} Months`}
            />
            <BillingCard
              name={"Belling Type"}
              secondName={account?.all?.data[0]?.billingType}
            />
            <BillingCard
              name={"Starting Date"}
              secondName={dayjs(account?.all?.data[0]?.startingDate).format(
                "YYYY-MM-DD"
              )}
            />
            <BillingCard name={"Ending Date"} secondName={dayjs(account?.all?.data[0]?.endingDate).format(
                "YYYY-MM-DD"
              )} />
            <BillingCard name={"Total AMount"} secondName={
                  account?.selected?.planType === "Weekly"
                    ? `${getDefaultCurrencyCode(company)} ${(
                        Number(props?.data?.customerInvoice?.data[0]?.amountToBePaid) *
                        Number(account?.all?.data[0]?.numberOfWeeks)
                      ).toLocaleString()}`
                    : account?.all?.data[0]?.planType === "Daily"
                    ? `${getDefaultCurrencyCode(company)} ${(
                        Number(props?.data?.customerInvoice?.data[0]?.amountToBePaid) *
                        Number(account?.all?.data[0]?.numberOfDays)
                      ).toLocaleString()}`
                    : `${getDefaultCurrencyCode(company)} ${(
                        Number(props?.data?.customerInvoice?.data[0]?.amountToBePaid) *
                        Number(account?.all?.data[0]?.duration)
                      ).toLocaleString()}`
                } />
          </div>
        </div></div>
        <div className=" bg-white w-full xl:w-[633px] rounded-md mt-5 xl:mt-0">
          <div className="flex justify-between border-b px-4 py-4">
            <h1 className="text-[#030229] text-base font-medium">
              Monthly Installment History
            </h1>
            <CustomButton
              title={"See All"}
              textColor={"[#605BFF]"}
              border={"[#605BFF40]"}
            />
          </div>
          <div className="mt-3 p-4">
            <InstallmentCard
              name={"Jan 12,2024"}
              secondName={"Monthly Installment (2 of 12)"}
              amount={`${getDefaultCurrencyCode(company)} 1,000,000`}
            />
            <InstallmentCard
              name={"Installment"}
              secondName={account?.all?.data[0]?.planType}
              amount= {`${getDefaultCurrencyCode(
                company
              )} ${props?.data?.customerInvoice?.data[0]?.amountToBePaid?.toLocaleString()}`}
            />
          </div>
        </div>
      </div>
      <Select
      className="my-5 "
          defaultValue=""
          style={{ width: 120 }}
          onChange={handleChange}
          options={[
            { value: "", label: "All" },
            { value: "paid", label: "Paid" },
            { value: "pending", label: "Pending" },
            { value: "late", label: "Late" },
          ]}
        />
      <div className="my-5">
      <ScrollableFrame
        setPage={props?.setPage}
        setLimit={props?.setLimit}
        limit={props?.limit}
        total={customer?.bellingInvoice?.total}
        count={Math.ceil(customer?.bellingInvoice?.total /props?.limit)}
      >
        <BellingDataTable data={customer}/>
        </ScrollableFrame>
      </div>
    </div>
  );
};

export default CustomerBillings;
