import React, { useState, useEffect } from "react";
import { Stack, useMediaQuery } from "@mui/material";
import { DatePicker, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import OverViewCard from "../../../components/cards/inventory/OverViewCard";
import { LoadingOutlined } from "@ant-design/icons";
import CustomerTable from "../../../components/tables/CustomerTable";
import DateRangePickers from "./DateRangePicker";
import { ReactComponent as Tcustomer } from "../../../icons/customers.svg";
import {
  getAllCustomersAction,
  getCustomersOverviewAction,
  getCustomersStatusAction,
  getRecentCustomersAction,
  getTopCustomersAction,
} from "../../../store/customer/actions";
import CustomerChart from "./CustomerChart";
import { Avatar, List } from "antd";
import CustomPieChart from "./CustomPieChart";
import { getActiveShop } from "../../../utils/converter";
import store from "store";

const PageView = (props: any) => {
  const dispatch = useDispatch();
  const { auth, customer, appSettings } = useSelector((state: any) => state);
 

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id ?? auth?.userShop?.shopId;

  useEffect(() => {
    auth?.token &&
      getTopCustomersAction(auth?.token, `?shopId=${shopId}`)(dispatch);
    auth?.token &&
      getCustomersOverviewAction(auth?.token, `?shop=${shopId}`)(dispatch);
    auth?.token && getAllCustomersAction(auth?.token)(dispatch);
    auth?.token &&
      getCustomersStatusAction(auth?.token, `?shop=${shopId}`)(dispatch);
    auth?.token &&
      getRecentCustomersAction(auth?.token, `?shop=${shopId}`)(dispatch);
  }, [auth?.token, dispatch, shopId]);

  return (
    <div className="text-black py-6 scrollbar-hide overflow-y-auto h-screen">
      <Stack spacing={3}>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
          <OverViewCard
            total={
              customer.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                customer?.customerOverview?.data?.all
              )
            }
            text="All Customers"
            icon={<Tcustomer className="mt-2" />}
          />
          <OverViewCard
            total={
              customer.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                customer?.customerOverview?.data?.active
              )
            }
            text="Active Customers"
            icon={<Tcustomer className="mt-2" />}
          />
          <OverViewCard
            total={
              customer.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                customer?.customerOverview?.data?.inactive
              )
            }
            text="Inactive Customers"
            icon={<Tcustomer className="mt-2" />}
          />
          <OverViewCard
            total={
              customer.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                customer?.customerOverview?.data?.b2cCustomers
              )
            }
            text="Individual Customers"
            icon={<Tcustomer className="mt-2" />}
          />
          <OverViewCard
            total={
              customer.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                customer?.customerOverview?.data?.b2cCustomers
              )
            }
            text="Business Customers"
            icon={<Tcustomer className="mt-2" />}
          />
        </div>
        <div className="p-5 xl:flex flex-row-reverse  gap-5 ">
          <div className="bg-white p-2 rounded-md w-full xl:w-[30%]">
            <DateRangePickers />
          </div>

          <div className="bg-white w-full xl:w-[30%] p-2 xl:mt-0 mt-5 rounded-md">
            <h1 className="text-[#030229CC] text-base font-semibold">
              Customers status
            </h1>
            <div className="mt-24">
              <CustomPieChart />
            </div>
          </div>
          <div className="bg-white w-full xl:w-[40%] p-5 xl:mt-0 mt-5 rounded-md">
            <div className="flex justify-between">
              {" "}
              <h1 className="text-[#030229CC] text-base font-semibold">
                Analytics
              </h1>
              <div className="flex gap-5 pb-5">
                <div className="flex flex-row items-center gap-1">
                  <div className="h-3 w-3 bg-[#0FA91E80] mr-1 rounded-full"></div>
                  <div className="text-[#030229] text-xs">Active Customers</div>
                </div>
                <div className="flex flex-row items-center gap-1">
                  <div className="h-3 w-3 bg-[#2943D680] mr-1 rounded-full"></div>
                  <div className="text-[#030229] text-xs">New Cstomers</div>
                </div>
              </div>
            </div>
            <CustomerChart />
          </div>
        </div>
        <div className="p-5 xl:flex gap-5">
          <div className="bg-white w-full xl:w-2/3 p-5 xl:mt-0 mt-5 rounded-md h-[384px] overflow-y-auto pr-3">
            <h1>Recent customers</h1>
            <CustomerTable customer={customer?.recentCustomer?.data} />
          </div>
          <div className="bg-white mt-5 xl:mt-0 w-full xl:w-1/3 p-5 rounded-xl h-[384px] overflow-y-auto pr-3">
            <div className="flex justify-between">
              <h1 className="text-[#030229CC] text-lg font-medium">
                Top Customers
              </h1>
            </div>
            <h1 className="text-[#03022980] text-base py-2">
              <List
                itemLayout="horizontal"
                dataSource={customer?.topCustomers?.data}
                renderItem={(item: any, index: any) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={item?.customer?.profile} />}
                      title={item?.customer?.name}
                      description={item?.customer?.email}
                    />
                  </List.Item>
                )}
              />
            </h1>
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default PageView;
