import React, { useEffect, useState } from "react";
import OverViewCard from "../../../components/cards/inventory/OverViewCard";
import { useDispatch, useSelector } from "react-redux";
import { Radio, Spin, Tabs } from "antd";
import { ReactComponent as BoxIcons } from "../../../assets/icons/dashboard/box-tick.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { Select } from "antd";
import CustomButton from "../../../components/buttons/CustomButton";
import { IoFilterOutline } from "react-icons/io5";
import "./style.css";
import { TbGridDots } from "react-icons/tb";
import { GoListUnordered } from "react-icons/go";
import type { RadioChangeEvent } from "antd";
import CurrentStockTable from "../../../components/tables/currentStockTable";
import { overViewStatActions } from "../../../store/posOverView/actions";
import { getActiveShop } from "../../../utils/converter";
import CurrentStockDetailsTable from "../../../components/tables/CurrentStokDetailsTable";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import {
  getAllStoclAction,
  getFreelanceStockAction,
} from "../../../store/pos/actions";
import TabPane from "antd/es/tabs/TabPane";
import StockForFreelancersTable from "../../../components/tables/StockForFreelancersTable";
import {
  getStockForOwnAssigneeActions,
  getStockStatusSCountActions,
} from "../../../store/inventory/actions";
import CSVExportButton from "../../../components/buttons/CSVExportButton";
import { CurrentStockHeaders } from "../../../components/headers/Csv_Header";
import { getStock_CSVAction } from "../../../store/csvDownload/actions";

const CurrentStock = () => {
  const { posOverView, auth, appSettings, pos, inventory, CSV, layout } =
    useSelector((state: any) => state);

  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const [showFilter, setShowFilter] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [value4, setValue4] = useState("pending");
  const [data, setData] = useState<any>(null);

  const shopId: any =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.userShop?.shopId;

  const handleViewDetails = () => {
    setViewDetails(true);
  };

  const onChange4 = ({ target: { value } }: RadioChangeEvent) => {
    setValue4(value);
  };

  const options = [
    { label: "Stok In", value: "pending", title: "approved" },
    { label: "Sold ", value: "sold" },
    { label: "Transfer", value: "transfer" },
  ];

  useEffect(() => {
    auth?.token && overViewStatActions(auth?.token, shopId)(dispatch);
  }, [auth?.token, dispatch, shopId]);

  useEffect(() => {
    if (auth?.token && shopId) {
      getAllStoclAction(
        auth?.token,
        `?shop=${shopId}&page=${page - 1}&limit=${limit}`
      )(dispatch);
    }
  }, [auth?.token, dispatch, shopId, limit, page]);

  useEffect(() => {
    if (layout?.openCSVButton && layout?.dataToExport === "stock") {
      getStock_CSVAction(
        auth?.token,
        `?shop=${shopId}&limit=${pos?.stock?.total}`
      )(dispatch);
    }
  }, [layout?.openCSVButton]);

  useEffect(() => {
    auth?.token &&
      getStockStatusSCountActions(auth?.token, `shop=${shopId}`)(dispatch);
  }, [auth?.token, dispatch, shopId, limit, page]);

  const csvData = CSV?.csvDownloadedData?.data?.map((item: any) => {
    return {
      model:
        item?.productSpecs?.product?.model +
        "~" +
        item?.productSpecs?.product?.type,
      specification: item?.productSpecs?.specification?.map(
        (d: any) => d[0] + `: ` + d[1]
      ),
      stockIn: item?.countsByStatus
        ?.filter((d: any) => d?.status?.toLowerCase() === "pending")
        ?.map((d: any) => d?.count),
      sold: item?.countsByStatus
        ?.filter((d: any) => d?.status?.toLowerCase() === "sold")
        ?.map((d: any) => d?.count),
      tranfer: item?.countsByStatus
        ?.filter((d: any) => d?.status?.toLowerCase() === "transfer")
        ?.map((d: any) => d?.count),
    };
  });
  return (
    <div className="text-black">
      <div className="flex gap-1">
        <DetailsHeaderActionBar
          goBack={() => setViewDetails(false)}
          title="Inventory"
        />
        <h1 className="text-[#030229d8] text-sm sm:text-base  md:text-lg pt-1">
          \ CurrentStock{" "}
          {viewDetails && (
            <span className="text-[14px]">
              <span className="text-lg">~ </span>
              {data?.model}
            </span>
          )}
        </h1>
      </div>
      <div className="grid sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mt-3">
        <OverViewCard
          total={
            pos.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.stockRequests || 0
            )
          }
          text="Total Requested"
          icon={<BoxIcons />}
        />
        <OverViewCard
          total={
            posOverView.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.totalCurrentDevices || 0
            )
          }
          text="Total Stock"
          icon={<BoxIcons />}
        />
        <OverViewCard
          total={
            posOverView.isFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.totalDevicesSold || 0
            )
          }
          text="Total Sold"
          icon={<BoxIcons />}
        />
        <OverViewCard
          total={
            pos.issFetching ? (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            ) : (
              posOverView?.overViewStats?.data?.transfer || 0
            )
          }
          text="Total Transfer"
          icon={<BoxIcons />}
        />
      </div>
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={`Available Products (${
            pos?.isFetching ? 0 : pos?.stock?.total
          })`}
          key="1"
          className="text-[16px]"
        >
          <div className="mt-4 flex justify-between">
            {!viewDetails ? (
              <>
                <div className="flex gap-5 flex-wrap">
                  <CustomButton
                    textColor="[#030229B2]"
                    icon={<IoFilterOutline size={20} />}
                    bgColor={"white"}
                    border="[#0302291A]"
                  />
                  {showFilter && (
                    <Select
                      showSearch
                      style={{ width: 200 }}
                      placeholder="filter by Model "
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={[
                        {
                          value: "1",
                          label: "Pop 8 (BC6)",
                        },
                        {
                          value: "2",
                          label: "Spark 20",
                        },
                        {
                          value: "3",
                          label: "Pop7",
                        },
                      ]}
                    />
                  )}
                </div>

                <div className="flex gap-5">
                  {layout?.dataToExport !== "freelancer" && (
                    <div>
                      <CSVExportButton
                        csvHeaders={CurrentStockHeaders}
                        csvData={csvData}
                        filename={`Stock.csv`}
                        directory={"stock"}
                      />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="flex justify-between w-full">
                <Radio.Group
                  options={options}
                  onChange={onChange4}
                  value={value4}
                  optionType="button"
                  buttonStyle="solid"
                />
                <div className="flex gap-5">
                  <div className="bg-[#605BFF] flex justify-center items-center h-[30px] w-[30px] mt-1.5 rounded-md">
                    <GoListUnordered size={23} color="#ffff" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-5 bg-white">
            {!viewDetails ? (
              <CurrentStockTable
                getData={() => handleViewDetails()}
                setData={setData}
                setPage={setPage}
                setLimit={setLimit}
                limit={limit}
              />
            ) : (
              <CurrentStockDetailsTable data={data} value={value4} />
            )}
          </div>
        </TabPane>

        <TabPane
          tab={`Freelancer Stock (${inventory?.stockStatusCount?.total || 0})`}
          key="2"
        >
          <StockForFreelancersTable />
        </TabPane>
        {/* } */}
      </Tabs>
    </div>
  );
};

export default CurrentStock;

