import React, { useEffect, useState } from "react";
import HeaderComponent from "../../components/HeaderComponent";
import { Skeleton, Stack } from "@mui/material";
import CustomerCard from "../../components/grids/CustomerGridCard";
import { useDispatch, useSelector } from "react-redux";
import AddNewCustomer from "../../components/forms/NewCustomer";
import {
  checkCustomerLoanAction,
  getAllCustomersAction,
  getOneCustomerAction,
  getOneCustomerByPhoneAction,
  verifyCustomerAction,
} from "../../store/customer/actions";
import ScrollableFrame from "../../components/layout/ScrollableFrame";
import NewSearchComponent from "../../components/buttons/NewSearchComponent";
import SearchImage from "../../assets/customerUndraw.svg";
import dayjs from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import { DatePicker, Form, Input, Modal, Spin } from "antd";
import PhoneInput from "react-phone-input-2";
import { myCustomerActions } from "../../store/customer";
import VerificationModal from "../../components/VerificationModal";

const CustomerPageView = () => {
  const { customer, auth } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(15);
  const [isVerifyModal, setIsVerifyModal] = useState(false);
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsVerifyModal(false);
  };

  const handleSearch = async () => {
    const userPhone = `25${searchKey}`;
    const res = await getOneCustomerByPhoneAction(`?phone=${`${userPhone}`}`)(
      dispatch
    );
    if (res) setIsVerifyModal(true);
  };

  const maskName = (name: string) => {
    if (!name) return "";
    return name?.substring(0, 2) + "*".repeat(name?.length - 2);
  };
  const originalFirstName = customer?.customerByPhone?.firstName;
  const originalLastName = customer?.customerByPhone?.lastName;
  const maskedFirstName = maskName(originalFirstName);
  const maskedLastName = maskName(originalLastName);

  const customerId =
    customer?.customerByPhone && customer?.customerByPhone?._id;

  const handleFinish = async (values: any) => {
    const formattedDateOfBirth = values.dateOfBirth
      ? dayjs(values.dateOfBirth).format("YYYY")
      : null;

    const isFirstNameValid = values.firstName === originalFirstName;
    const isLastNameValid = values.lastName === originalLastName;
    const isDateValid =
      formattedDateOfBirth ===
      dayjs(customer?.customerByPhone?.dateOfBirth).format("YYYY");

    if (isFirstNameValid || isLastNameValid || isDateValid) {
      auth.token && getOneCustomerAction(auth?.token, customerId)(dispatch);
      setIsVerifyModal(false);
    } else {
      Modal.error({
        title: "Verification Failed",
        content:
          "Please verify using either your first name, last name, or year of birth.",
      });
    }
  };
  useEffect(() => {
    if (customer?.customerByPhone) {
      form.setFieldsValue({
        firstName: maskedFirstName,
        lastName: maskedLastName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.customerByPhone, form]);

  return (
    <Stack spacing={1} style={{ marginTop: "1.5rem" }}>
      <div className="flex items-center justify-between px-6 mb-4 pr-12 pt-2">
        <NewSearchComponent
          onSearch={handleSearch}
          onChange={(e: any) => {
            e.preventDefault();
            setSearchKey(e.target.value);
          }}
        />

        <VerificationModal
          isVerifyModal={isVerifyModal}
          handleCancel={handleCancel}
          customer={customer}
          onVerificationSuccess={() => {
            auth.token &&
              getOneCustomerAction(auth?.token, customerId)(dispatch);
            setIsVerifyModal(false);
          }}
          form={form}
        />
        <HeaderComponent
          item="Customer"
          modelTitle="Create New Customer"
          ModelComponent={
            <AddNewCustomer onCancel={handleCancel} limit={limit} page={page} />
          }
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
        />
      </div>

      <Stack spacing={1}>
        <ScrollableFrame
          loading={customer?.isFetching}
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={customer?.all?.total}
          count={Math.ceil(customer?.all?.total / limit)}
        >
          {customer.isFetching && (
            <div className="flex flex-wrap gap-4 justify-start items-start">
              {Array.from({ length: 14 }).map((_, index) => (
                <div key={index} className="w-80">
                  <Skeleton height={200} />
                </div>
              ))}
            </div>
          )}
          <div className="flex flex-wrap gap-4 py-2 rounded-lgz">
            {!customer?.selected ? (
              <div className="w-full flex justify-center items-center flex-col mt-8">
                <img
                  src={SearchImage}
                  alt="searchImage"
                  className="w-1/3 h-auto mb-4"
                />

                <p className="text-gray-500 text-lg">Search customer</p>
              </div>
            ) : (
              <CustomerCard
                key={customer?.selected?._id}
                data={customer?.selected}
              />
            )}
          </div>
        </ScrollableFrame>
      </Stack>
    </Stack>
  );
};

export default CustomerPageView;
